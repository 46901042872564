/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import React, { useEffect, input, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';

import dayjs from 'dayjs';

import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [DateOfBirth, setDateOfBirth] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [message, setMessage] = React.useState('');
  const [allOrgCategories, setAllOrgCategories] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [catLabel, setCatLabel] = useState('');
  const [fields, setFields] = useState([{ name: '', email: '', phone: '' }]);

  const handleBackClick = () => {
    navigate(-1)
  };
  const handleAddFields = () => {
    const values = [...fields];
    values.push({ name: '', email: '', phone: '' });
    setFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const handleChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  // --------------------------------------------------------------

  const handleDate = (newValue) => {
    setDateOfBirth(newValue);
  };

  // ---------------------------------------------------------
  const [image, setImage] = React.useState('');
  const handlePhoto = (event) => {
    setImage(event.target.value);
    console.log(image);
  };
  // const [showPassword, setShowPassword] = useState(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    cnic: Yup.string().required('CNIC is required').matches('^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$', 'CNIC is not valid'),
    contact: Yup.string()
      .required('Contact is required')
      .matches(phoneRegExp, 'Phone number does not match the formate')
      .min(11, 'to short')
      .max(11, 'to long'),
    organization: Yup.string().required('Address is required'),
    current_salary: Yup.string().required('Category is required'),
  });

  const defaultValues = {
    name: '',
    cnic: '',
    contact: '',
    organization: '',
    month: '',
    current_salary: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
      console.log(data)
    const dataObject = {
      name: data.name,
      org_id: data.organization,
      user_type: '3',
      cnic: data.cnic,
      date_of_birth: DateOfBirth,
      month: data.month,
      contact: data.contact,
      // image: base64code,
      role_id: 'employee',
      salary: data.current_salary,
      role: 'employee',
    };
    console.log(JSON.stringify(dataObject));
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataObject),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_user', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            navigate('/admindashboard/admin/hr/manageuser', { replace: true });
          }, 1500);
        } else {
          setMessage(data.message);
        }
        console.log(data);
      });
  };

  // ----------------------------------------------

  const MonthArray = [
    'Select One',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const PreRequiredApproval = ['Select One', 'Yes', 'No'];

  const Roles = ['Select One', 'Admin', 'Employee', 'Firm'];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  const getOrgCategories = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organization_categories')
      .then((response) => response.json())
      .then((json) => {
        setAllOrgCategories(json.data);
        return json.data;
      })
      .then((data) => {
        setCatLabel(data[0].category_title);
      });
  };

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };
  useEffect(() => {
    getOrgCategories();
    getAllOrg();
  }, []);

  // ----------------------------------------------

  //  ============================= image to base64 ======================================= //
  let base64code = '';
  let UserImage;
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
    UserImage = file.name;
  };

  const onLoad = (fileString) => {
    console.log(fileString);
    base64code = fileString;
  };

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  //  ==================================== image to base64 ========================================= //

  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };
  return (
    <>
      {/* ======================= front Heading =============================================== */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {message ? (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#b3ffb3',
              alignItems: 'center',
              position: 'absolute',
              width: '70%',
              borderRadius: 10,
            }}
            variant="h4"
            gutterBottom
          >
            <Typography style={{ color: 'green' }} variant="h4" gutterBottom>
              {message}
            </Typography>
          </Typography>
        ) : null}

        <Typography variant="h4" gutterBottom>
          Add User
        </Typography>
        <Button
          variant="outlined"
          component={RouterLink}
          style={{ marginRight: 5 }}
          to="#"
          onClick={handleBackClick}
        >
          User List
        </Button>
      </Stack>
      {/* ======================= front Heading end =============================================== */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={3}
          style={{ backgroundColor: 'white', padding: 30, borderRadius: 10, boxShadow: '1px 1px 1px #999999' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} mb={2}>
              <RHFTextField name="name" label="Write your name" />
            </Grid>

            <Grid item xs={12} md={3}>
              <RHFTextField name="cnic" label="CNIC (XXXXX-XXXXXXX-X)" />
            </Grid>

            <Grid item xs={12} md={3}>
              <RHFTextField name="contact" label="Contact (03XXXXXXXXX)" />
            </Grid>

            <Grid item xs={12} md={3} mb={2}>
              <RHSelect name="organization">
                <option>Select Organization</option>
                {allOrg.map((org) => (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_title}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12}>
              {fields.map((field, index) => (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} key={index}>
                  <Grid item xs={12} md={3} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Data of Salary Credit"
                        inputFormat="MM/DD/YYYY"
                        value={DateOfBirth}
                        onChange={handleDate}
                        renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={3} mb={2}>
                    <RHSelect name="month" label="Month">
                      {MonthArray.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </RHSelect>
                  </Grid>

                  <Grid item xs={12} md={3} mb={2}>
                    <RHFTextField name="current_salary" label="Current Salary" />
                  </Grid>
                  <div style={{ display: 'flex', justifyContent: 'center', aligItems: 'center', height: 55 }}>
                    {index === 0 ? <Button onClick={() => handleAddFields()}>
                      <Icon
                        icon="ic:baseline-plus"
                        style={{ fontSize: '24px', color: '#fff', backgroundColor: '#0003bf', borderRadius: 50 }}
                      />
                    </Button> : null}
                    {index > 0 ? (
                      <Button onClick={() => handleRemoveFields(index)}>
                        <Icon
                          icon="ic:baseline-minus"
                          style={{ fontSize: '24px', color: '#fff', backgroundColor: '#52000b', borderRadius: 50 }}
                        />
                      </Button>
                    ) : null}
                  </div>
                </div>
              ))}
            </Grid>

            <Grid item xs={12} md={3} mb={2}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

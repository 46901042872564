/* eslint-disable prefer-destructuring */
import * as Yup from 'yup';
import React, { useState, input, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
// ----------------------------------------------------------------------

export default function UpdateModules({allOrgCategories, id, onClose }) {
  const navigate = useNavigate();
//   console.log(moduleData)
const [ editData, setEditData ] = useState('');
const dataFunction = () => {
    allOrgCategories.map((d) => {
        const singleEdit = d.category_id === id ? setEditData(d) : null
        return singleEdit
    } )
}

useEffect(() =>{
    dataFunction()
})
  const [message, setMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    org_title: Yup.string(),
    org_category: Yup.string()
  });

  const defaultValues = {
    org_title: '',
    org_category: '',
  
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  function refreshPage() {
    window.location.reload(false);
  }

  const [catTitle, setCatTitle] = useState('')
  const [orgType, setOrgType] = useState('')
  const [image, setImage] = React.useState('');
  const[dataImage, setDataImage] = useState("")


  const styles = {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      height: 55,
      padding: 5,
      borderRadius: 7,
      width: '100%',
      paddingTop: 15,
    };
  
  const handleCategoryImage = (event) => {
    setImage(event.target.value);
    // console.log(image);
  };

 
      //  ============================= image to base64 ======================================= //
      let UserImage;
      const onChange = e => {
      const files = e.target.files;
      const file = files[0];
      getBase64(file);
      UserImage =file.name
      console.log(UserImage)

    };
    
    const onLoad = fileString => {
      // console.log(fileString);
      setDataImage(fileString)
    };
    
    const getBase64 = file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
      };
    };
  
    
      
    //  ==================================== image to base64 ========================================= //
    const onSubmit = async (data) => {
      // console.log(data)
      const objectModuleData = {
        
          "category_title": catTitle === ''? editData.category_title : catTitle,
          "image ": dataImage === '' ? editData.image : dataImage,
          "category_id" : id,
          
      }
      console.log(JSON.stringify(objectModuleData));
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(objectModuleData),
      };
      fetch('https://lms.firefly-techsolutions.com/services/web/api/update_organization_category', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.type === 'success') {
            window.scrollTo(0, 0);
            setMessage(data.message);
            setTimeout(() => {
              onClose();
              setMessage('');
              refreshPage()
            }, 1000);
          }else{
            setMessage(data.message)
          }
        });
    };
  
  // ----------------------------------------------


  return (
    <>
      { message ? (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#1a53ff',
            alignItems: 'center',
            // position: 'absolute',
            width: '100%',
            borderRadius: 10,
            marginBottom: 20,
            zIndex: 99
          }}
          variant="h6"
          
        >
          <Typography style={{ color: 'white' }} variant="h6" >
            {message}
          </Typography>
        </Typography>
      ) : null
}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Category Title</p>
            <TextField  style={{width:400}} name="category_title"  value={catTitle} onChange ={(text)=> setCatTitle( text.target.value)}   label={editData.category_title} />
          </Grid>
       
          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>Upload Image</p>
            <input style={styles} type="file" name="image" label={editData.image} value={UserImage} onChange={onChange} />
          </Grid>
    
  
            <Grid item xs={12} md={12}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './CheckboxTree.css';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile,
} from 'react-icons/md';
import { Grid, Button, Typography } from '@mui/material';

const WidgetTree = ({ nodes, selectValue }) => {
  const [checked, setChecked] = React.useState([]);
  const [expanded, setExpanded] = React.useState([]);
  const [clicked, setClicked] = React.useState({});
  const [ message, setMessage ] = React.useState('');

  const onClick = (clicked) => {
  //  setClicked(cl)
  };

  const onCheck = (checked, clicked) => {
    if(checked[0] === clicked.value) {
      setChecked(checked)
    }else{
      setChecked(checked)
      if(clicked.children){
        checked.push(clicked.value)
      }else{
        return null
      }
    }
  };

  const onSubmit = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:  JSON.stringify({ role_id: selectValue, modules: { checked } }),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/assign_modules', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.message === 'Modules Assigned Successfully'){
          setMessage(data.message)
          setTimeout(() => {
            setMessage('');
          }, 2000);
        }else{
          setMessage(data.message)
          setTimeout(() => {
            setMessage('');
          }, 2000);
        }
      });
  };

  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />,
    expandClose: <MdChevronRight className="rct-icon rct-icon-expand-close" />,
    expandOpen: <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />,
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />,
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />,
  };
  return (
    <>
    { message ? (
            <Typography style={{ display: 'flex' , justifyContent: 'center', backgroundColor: '#b3ffb3', alignItems : "center",   position: 'absolute', width: '100%', borderRadius: 10}} variant="p" >
            <Typography style={{ color: 'green'   }} variant="p">
            {message}
          </Typography>
          </Typography>
        ) : null }
        
      <div className="clickable-labels-info" style={{ paddingBottom: 40, paddingLeft: 10 }}>
        <strong style={{ fontSize: 14 }}>Clicked Node</strong>: {clicked.label || ''}
      </div>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={(checked, clicked) => onCheck(checked, clicked)}
        onExpand={(expanded) => setExpanded(expanded)}
        onClick={onClick}
        icons={icons}
        showExpandAll
      />
      <Grid style={{ width: '100%' }}>
        <Button onClick={onSubmit} style={{ left: 200, marginTop: 50 }} variant="outlined">
          {' '}
          Submit
        </Button>
      </Grid>
    </>
  );
};
export default WidgetTree;

/* eslint-disable react/prop-types */
import React, { useState, input, useEffect } from 'react';
import { Stack, Grid, Button, Typography } from '@mui/material';
// ----------------------------------------------------------------------

export default function DeleteCategory({ onClick, text, buttondelete, buttonclose,  ClickDelete }) {





  return (
    <Stack spacing={2}>
     
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} md={12}>
          <p style={{ fontSize: 15, marginBottom: 5, color: 'gray' }}>{text}</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button style={{ height: 40 }} onClick={ClickDelete} fullWidth size="large" type="submit" variant="outlined">
            {buttondelete}
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            style={{ height: 40 }}
            onClick={onClick}
            color="error"
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
          >
            {buttonclose}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

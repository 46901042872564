import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function UserDetail(props) {
    const { title, children, openPopup, onClick } = props;

    return (
        <div>
            <Dialog open={openPopup} maxWidth="md"   >
                <DialogTitle >
                    {title}
                    <IconButton style={{ position: "absolute", top: 9 , right: 5, color: 'red', }} onClick={onClick}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>
            </Dialog>


        </div>
    )
}
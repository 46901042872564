/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import CheckboxTree from 'react-checkbox-tree';
import CheckboxTreeN from "./CheckboxTree";


const names = [ 'Select One', 'admin', 'HR', 'Firm']



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function AssignModules() {

  const theme = useTheme();
  const [personName, setPersonName] = useState('');
  const [ checked, setChecked ] = React.useState([]);
  const [ userData, setUserData] = useState([]);
  const [ roles, setRoles] = useState([]);
  // ======================Data ================================================================= //
  const dataArray = [];

  const handledropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(value);

  };

  // api get data from database
 
const getAllModuleData = () => (
  fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_modules')
      .then(response => response.json())
      .then(( json) => {
        Object.values(json.data).map(key => 
          dataArray.push(key)
      )
      }).then(() => {
        dataArray.map((item) => item.children ? Object.assign(item, { value: item.id}) && item.children.map((i) => Object.assign(i, {value: i.id})) : Object.assign(item, { value: item.id}) )
      }).then(() => setUserData(dataArray))

  
)

 
// -------------------------------------- /Get ROles ////////////////////////////


  const getAllRoles = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_roles')
      .then((response) => response.json())
      .then(( json) => {
        setRoles(json.data)
      })
  };
// -------------------------------------- /Get ROles End ////////////////////////////




  // useEffect to run api function
  useEffect(() => {
    // if(personName) { 
    //   getModuleById()
    // }
// console.log({ role_id : personName})
    getAllRoles()
    getAllModuleData()
  }, [userData, roles]);

  return (
    <Grid container justifyContent="center">
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Select Role</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={personName}
          onChange={handledropdown}
          input={<OutlinedInput label="Select Role" />}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {roles.map((name) => (
            <MenuItem key={name.role_id} value={name.role_id} style={getStyles(name, personName, theme)}>
              {name.role_title}
            </MenuItem>
          ))}
        </Select>
      { personName  ? <Grid style={{ borderRadius: 10,  marginTop: 10, backgroundColor: 'white', paddingTop: 50, paddingBottom: 50, boxShadow: `1px 1px 1px #d5dbf2`}}> <CheckboxTreeN selectValue={personName} nodes={userData}/> </Grid> : null}
      </FormControl>
  
    </Grid>
  );
}

/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
import React,{useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TextField ,Box, Stack, Typography,Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {AssignedPopup} from '../../sections/@dashboard/user'
import Iconify from '../../components/Iconify';
import AddUser from './AddUser'
import UpdateRoles from './UpdateRoles'
import DeleteRole from './DeleteRole'


const data = [
    {id:1, name: "Admin" },
    {id:2, name: "Employee" },
    {id:3, name: "Firm"},
  ]


// ================================================================================== //
export default function ManageRoles() {
  const [status, setStatus] = useState(false)
  const [ anyId, setAnyId] = useState('');
const [openPopup, setOpenPopup] = useState(false);
const [role_title, setEnterValue] = React.useState('');
const [message, setMessage] = React.useState('');
const [allRole, setAllRole] = useState([])
const navigate = useNavigate();
const [updatePopup, setUpdatePopup]  = useState(false);
const [RoleId, setRoleId] = useState('')
const [deleteUserPopUp, setDeleteUserPopUp] = useState(false);
const [deleteId, setDeleteId] = useState('')


const handleUpdateClose = () => {
  setStatus(false);
};

// console.log(RoleId)



    const [changevalue, setChangeValue] = useState('');
    const [ remove, setRemove ] = useState('');


 const showPopUp = (id) => {
    setStatus(true)
    setAnyId(id)
  }

  const handleStatusClose = () => {
    setStatus(false);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };
  
  const changeValue = (ev) => {
    setEnterValue(ev.target.value)
  }

  // const onClickEvent = () => {
  //   Data.push( { id: Data.length + 1, organization: enterValue})
  //   handleClose()
  //   console.log(Data)

// }
// const deleteOrg = async () => {

//   console.log(JSON.stringify({'org_id' : deleteId}));

//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({'org_id' : deleteId}),
//   };
//   fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_organization', requestOptions)
//     .then((response) => response.json())
//     .then((data) => {
//       console.log(data);
//       if (data.type === 'success') {
//         window.scrollTo(0, 0);
//         setMessage(data.message);
//         setTimeout(() => {
//           navigate('/admindashboard/admin/hr/manageorganization', { replace: true });
//         }, 1500);
//         refreshPage()
//       } else {
//         setMessage(data.message);
//       }
//     });
// };



  const getAllModule = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_roles')
      .then((response) => response.json())
      .then((json) => setAllRole(json.data));
  };
  useEffect(() => {
    getAllModule()
  }, []);
  // console.log(allRole)

  const onSubmit = async () => {
   
    console.log(JSON.stringify({ 'role_title' : role_title}))
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'role_title' : role_title})
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_role', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
         if(data.type === 'success'){
          window.scrollTo(0, 0)
          setMessage(data.message)
          
          setTimeout( () => { handleClose(); setMessage('') }, 1500);
         
         }else{
          setMessage(data.message)
         } 
        });
  };



    
  
  const  updateStatus = () => {
    data.map((val)=>{
        if (val.id === anyId){
            val.name = changevalue
        } 
        return null;
    })
    handleStatusClose()
    // return show
  }


      
  const handleDeleteClose = () => {
    setDeleteUserPopUp(false);
  };
  // ======================  Org Delete start =====================================//
  const deleteUser = () => {
    console.log(JSON.stringify({ 'org_id' : deleteId}))
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'org_id' : deleteId}),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_user', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };

  // ======================  Org Delete end =====================================//

  return (
    <>

<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{ margin: 20}}>
            <Typography variant="h4" gutterBottom>
              Manage Roles
            </Typography>
            <Button
              onClick={() => setOpenPopup(true)}
              style={{backgroundColor:'white',
            border: '1px solid blue', }}
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create New Roles
            </Button>
          </Stack>
    
    <Card sx={{ minWidth: 275,mt:2 }}>
      <CardContent>
      <div style={{
        width: "100%",
         height: "100%",
         display: "flex",
        justifyContent: "center",
         alignItems: "center",
  }}>
      <table style={{
        
        width: "100%",
        height: "100%",
        alignItem:'center',
        justifyContent:'center',
      }}>
         <tr>
          <th style={{width:"50%",color:'blue',fontSize:14,textAlign:"center",borderBottom:"1px solid #d6d6d6",padding:20,fontWeight:'200',letterSpacing:1}}> ROLE TITLE</th>
          <th style={{width:'50%',color:'blue',fontSize:14,textAlign:"center", borderBottom:"1px solid #d6d6d6",fontWeight:'200',letterSpacing:1 }}>ACTION</th>
        </tr>

        { allRole.map((val,key,)=>{
            return (
                <>
                
                <tr key ={key}>
                <td style={{width:"50%",color:'#8f8c8c',fontSize:13,textAlign:"center",borderBottom:"1px solid #d6d6d6",padding:20}}>{val.role_title}</td>
                <td style={{width:"50%",color:'#8f8c8c',fontSize:13,textAlign:"center",borderBottom:"1px solid #d6d6d6",padding:20}}>
                  <Button  onClick={() => {showPopUp(true); setRoleId(val.role_id)}}  style={{left:20,borderWidth:5,fontSize:13,borderRadius:50,borderColor:'red'}}>Edit</Button>
                  <Button onClick ={()=>{ setDeleteUserPopUp(true); setDeleteId(val.role_id)}} style={{left:20,fontSize:13}}>Delete</Button>
                </td>
                </tr>
                </>
            )
        })
        }
      </table>
    </div>
      </CardContent>
      
    </Card>

    <AddUser
       openPopup={status} 
       setOpenPopup={setStatus} 
       title="Update Roles" 
       onClick={handleUpdateClose}
      
       >

    <UpdateRoles RoleId={RoleId} allRole={allRole} />
  
    
      </AddUser>
      <AddUser
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Role"
        onClick={handleClose}
        >
         { message ?  <Typography style={{ color: '#03996d', fontWeight: 'bold', backgroundColor: '#cbf7eb', padding: 10, borderRadius: 10 }}>{message}</Typography> : null}
          <Stack spacing={3}>
            <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Add Role</p>
            <TextField  style={{width:400}} name="Add Role"   label="Enter Title" onChange={changeValue} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button fullWidth size="large" type="submit" variant="contained" onClick={onSubmit}>
              Add
            </Button>
       
          </Grid>
          </Stack>
    

      </AddUser>

      <AddUser
        openPopup={deleteUserPopUp}
        setOpenPopup={setDeleteUserPopUp}
        title="Delete Role"
        onClick={handleDeleteClose}

      >
  
        <DeleteRole  ClickDelete={() =>{ deleteUser(); setDeleteUserPopUp(false)}} onClick={()=> setDeleteUserPopUp(false)} text="Are you sure you want to delete this Role?" buttondelete= "Delete" buttonclose="Close" />
      </AddUser>

    </>
  );
}



import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@mui/material';


export default function UserDetail(props) {
    const { title, children, openPopup, onClick, onSubmit } = props;

    return (
        <div>
            <Dialog open={openPopup} maxWidth="md"   >
                <DialogTitle >
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button style={{backgroundColor:'#00b33c', color:'white'}} onClick={onSubmit}>Re-Submit</Button>
                    <Button style={{backgroundColor:'#ff4d4d', color:'white', width:'16%', textAlign:'center'}} onClick={onClick}>Close</Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}
import React,{useState,useEffect} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, MenuItem } from '@material-ui/core';
import { Stack, Grid, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  cnic: Yup.string().required('CNIC is required').matches(/^[0-9]{5}[0-9]{7}[0-9]{1}$/, 'CNIC is not valid'),
  contact: Yup.string()
    .required('Contact is required')
    .matches(phoneRegExp, 'Phone number does not match the formate')
    .min(11, 'to short')
    .max(11, 'to long'),
  // currentsalary: Yup.string().required('Current Salary is required'),
  password: Yup.string()
  .required('Password is required')
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.'),
  cnf_password: Yup.string().required('Confirm Password is required'),
  salary: Yup.array().of(
    Yup.object().shape({
      salary_date: Yup.string().required('Salary Date is required'),
      salary_month: Yup.string().required('Month is required'),
      net_salary: Yup.string().required('Net Salary is required'),
    })
  ),
});

const defaultValues = {
  name: '',
  cnic: '',
  contact: '',
  current_salary: '',
  password:'',
  cnf_password:'',
  salary: [{ salary_date: '', salary_month: '', net_salary: '' }],
};





const MonthArray = [
  'Select One',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Form = () => {
  const [message, setMessage] = React.useState('');
  const [allOrg, setAllOrg] = React.useState([]);
  const[ userData, setUserData ] = useState('');

  useEffect(()=> {
    const items = JSON.parse(localStorage.getItem('data'));
    if (items) {
     setUserData(items.user)
    }
  
  },[])

  const refreshPages = ()=> {
    window.location.reload(false);
  }

const onSubmitEmployee = async (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({  
      org_id: userData.org_id, 
      name:data.name,
      cnic:data.cnic,
      current_salary:data.current_salary,
      contact:data.contact,
      password:data.password,
      cnf_password:data.cnf_password,
      salary:data.salary })
  };
  fetch('https://lms.firefly-techsolutions.com/services/web/api/add_employee', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.type === 'success') {
        window.scrollTo(0, 0);
        setMessage(data.message);
        setTimeout(() => {
          setMessage('');
          refreshPages()
        }, 1000);
      } else {
        setMessage(data.message);
      }
    });
};

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = methods;
  const salary = watch('salary');

  const handleAddField = () => {
    reset({ salary: [...salary, { salary_date: '', salary_month: '', net_salary: '' }] });
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...salary];
    updatedFields.splice(index, 1);
    reset({ salary: updatedFields });
  };

  //   /* ======================= API Function =============================================== */

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };

  //   /* ======================= API Caling =============================================== */

  React.useEffect(() => {
    getAllOrg();
  }, []);

  //   /* ======================= API Caling =============================================== */

  return (
    <>
      {/* ======================= front Heading =============================================== */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {message ? (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#b3ffb3',
              alignItems: 'center',
              position: 'absolute',
              width: '70%',
              borderRadius: 10,
            }}
            variant="h4"
            gutterBottom
          >
            <Typography style={{ color: 'green' }} variant="h4" gutterBottom>
              {message}
            </Typography>
          </Typography>
        ) : null}

        <Typography variant="h4" gutterBottom>
          Add User
        </Typography>
        {/* <Button
          variant="outlined"
          component={RouterLink}
          style={{ marginRight: 5 }}
          to="/admindashboard/admin/hr/manageuser"
        >
          User List
        </Button> */}
      </Stack>
      {/* ======================= front Heading end =============================================== */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmitEmployee(data))}>
          <Stack
            spacing={3}
            style={{ backgroundColor: 'white', padding: 30, borderRadius: 10, boxShadow: '1px 1px 1px #999999' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} mb={2}>
                <TextField
                  {...methods.register('name')}
                  label="Name"
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4} mb={2}>
                <TextField
                  {...methods.register('cnic')}
                  label="CNIC (xxxxxxxxxxxxx)"
                  error={Boolean(errors.cnic)}
                  helperText={errors.cnic?.message}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4} mb={2}>
                <TextField
                  {...methods.register('contact')}
                  label="Contact (03xxxxxxxxx)"
                  error={Boolean(errors.contact)}
                  helperText={errors.contact?.message}
                  type="number"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={4} mb={2}>
                <TextField
                  {...methods.register('current_salary')}
                  label="Current Salary (PKR)"
                  error={Boolean(errors.current_salary)}
                  helperText={errors.current_salary?.message}
                  type="number"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={4} mb={2}>
                <TextField
                 {...methods.register(`password`)}
                  label="Password"
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  variant="outlined"
                  type="password"
                  />
              </Grid>

              <Grid item xs={12} md={4} mb={2}>
                      <TextField
                        {...methods.register(`cnf_password`)}
                        label="Confirm Password"
                        error={Boolean(errors.cnf_password)}
                        helperText={errors.cnf_password?.message}
                        variant="outlined"
                        type="password"
                      />
              </Grid>
              {/* <Grid item xs={12} md={3} mb={2}>
                <TextField
                  style={{ width: 220 }}
                  {...methods.register(`organization`)}
                  label="Organization"
                  error={Boolean(errors.organization)}
                  helperText={errors.organization?.message}
                  variant="outlined"
                  select
                >
                  {allOrg?.map((item, index) => (
                    <MenuItem key={index} value={item.org_id}>
                      {item.org_title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
              <Grid item xs={12}>
                {salary.map((field, index) => (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} key={index}>
                    <Grid item xs={12} md={3} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date of Salary Credit"
                          value={field.salary_date} // value of salary_date from the current field object
                          onChange={(date) => {
                            const updatedFields = [...salary];
                            updatedFields[index].salary_date = date; // update salary_date value of the current field object
                            reset({ salary: updatedFields }); // reset the salary array with updatedFields
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.salary?.[index]?.salary_date)}
                              helperText={errors.salary?.[index]?.salary_date?.message}
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={3} mb={2}>
                      <TextField
                        style={{ width: 220 }}
                        {...methods.register(`salary.${index}.salary_month`)}
                        label="month"
                        error={Boolean(errors.salary?.[index]?.salary_month)}
                        helperText={errors.salary?.[index]?.salary_month?.message}
                        variant="outlined"
                        select
                      >
                        {MonthArray?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} mb={2}>
                      <TextField
                        {...methods.register(`salary.${index}.net_salary`)}
                        label="Net Salary"
                        error={Boolean(errors.salary?.[index]?.net_salary)}
                        helperText={errors.salary?.[index]?.net_salary?.message}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>

              


                    <div style={{ display: 'flex', justifyContent: 'center', aligItems: 'center', height: 55 }}>
                      {index === 0 ? (
                        <Button onClick={() => handleAddField()}>
                          <Icon
                            icon="ic:baseline-plus"
                            style={{ fontSize: '24px', color: '#fff', backgroundColor: '#0003bf', borderRadius: 50 }}
                          />
                        </Button>
                      ) : null}
                      {index > 0 ? (
                        <Button onClick={() => handleRemoveField(index)}>
                          <Icon
                            icon="ic:baseline-minus"
                            style={{ fontSize: '24px', color: '#fff', backgroundColor: '#52000b', borderRadius: 50 }}
                          />
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={12} md={3} mb={2}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;

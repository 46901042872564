/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import React, { useEffect, input, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';

import dayjs from 'dayjs';

import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function EditEmployee(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [DateOfBirth, setDateOfBirth] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [message, setMessage] = React.useState('');
const [ allOrgCategories, setAllOrgCategories] = useState([])
const [ allOrg, setAllOrg] = useState([])
const [ catLabel, setCatLabel] = useState('')

  

  // --------------------------------------------------------------

  const handleDate = (newValue) => {
    setDateOfBirth(newValue);
  };
const location = useLocation();
console.log(location.state.row);

  // ---------------------------------------------------------
  const [image, setImage] = React.useState('');
  const handlePhoto = (event) => {
    setImage(event.target.value);
    console.log(image);
  };
  // const [showPassword, setShowPassword] = useState(false);
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const LoginSchema = Yup.object().shape({
    username: Yup.string().email('Email must be a valid email address').required('Email is required'),
    name: Yup.string().required('Name is required'),
    password: Yup.string().required('Password is required'),
    confPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Password must match'),
    father_name: Yup.string().required('Father Name is required'),
    cnic: Yup.string().required('CNIC is required').matches('^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$', "CNIC is not valid"),
    city: Yup.string().required('City is required'),
    contact: Yup.string().required('Contact is required').matches(phoneRegExp, 'Phone number does not match the formate').min(11, "to short")
    .max(11, "to long"),
    address1: Yup.string().required('Address is required'),
    // category: Yup.string().required('Category is required'),
  });
const { name,phone, sno, title, empid, address, id, cnic, loanlimit } = location.state.row;
  const defaultValues = {
    username: '',
    password: '',
    confPassword: '',
    name: name || '',
    father_name: '',
    org_id: '',
    cnic:  cnic || '',
    city: '',
    contact: phone || '',
    // org_title: '',
    gender: '',
    country: '',
    address1: address || '',
    approvalRequired: '',
    // org_type: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  
 
  const onSubmit = async (data) => {
    const dataObject = {
      'username': data.username,
      'name': data.name,
      'father_name': data.father_name,
    //   'org_id': data.organization,
      'user_type': '3',
      'cnic' : data.cnic,
      'city': data.city,
    //   'org_title': data.organization,
      'date_of_birth': DateOfBirth,
      'gender' : data.gender === 'Male' ? '0' : '1',
      'contact': data.contact,
      'image': base64code,
      'address' : data.address1,
      'password' : data.password,
      'role_id' : data.role,
      'pre_approval_required' : data.approvalRequired === 'Yes' ? '1' : '0',
      'email': data.contact_email,
      'salary': data.current_salary,
    //   'org_type' : data.org_type
    }
    console.log(JSON.stringify(dataObject)) 
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataObject)
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_user', requestOptions)
        .then(response => response.json())
        .then(data => {
         if(data.type === 'success'){
          window.scrollTo(0, 0)
          setMessage(data.message)
          setTimeout( () => { navigate('/admindashboard/admin/hr/manageuser', { replace: true}) }, 1500);
         
         }else{
          setMessage(data.message)
         } 
         console.log(data)
        });
  };

  // ----------------------------------------------

  const CATEGORY_OPTION = ['Select One', 'Male', 'Female'];
  const PreRequiredApproval = ['Select One', 'Yes', 'No'];


  const Roles = ['Select One', 'Admin', 'Employee', 'Firm'];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }


  
  const getOrgCategories = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organization_categories')
      .then((response) => response.json())
      .then((json) => {
        setAllOrgCategories(json.data); 
        return json.data
      })
      .then((data) =>{ setCatLabel(data[0].category_title)});
  };

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };
  useEffect(() => {
    getOrgCategories()
    getAllOrg()
  }, []);


  // ----------------------------------------------

    //  ============================= image to base64 ======================================= //
    let base64code = ""
    let UserImage;
    const onChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
    UserImage =file.name
  };
  
  const onLoad = fileString => {
    console.log(fileString);
    base64code = fileString
  };
  
  const getBase64 = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  
    
  //  ==================================== image to base64 ========================================= //

  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };
  return (
    <>
      {/* ======================= front Heading =============================================== */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        { message ? (
            <Typography style={{ display: 'flex' , justifyContent: 'center', backgroundColor: '#b3ffb3', alignItems : "center",   position: 'absolute', width: '70%', borderRadius: 10}} variant="h4" gutterBottom>
            <Typography style={{ color: 'green'   }} variant="h4" gutterBottom>
            {message}
          </Typography>
          </Typography>
        ) : null }
        
        <Typography variant="h4" gutterBottom>
          Add Employee
        </Typography>
        <Button
          variant="outlined"
          component={RouterLink}
          style={{ marginRight: 5 }}
          to="/firmdashboard/firmapp/employeeList"
        >
          Employees List
        </Button>
      </Stack>
      {/* ======================= front Heading end =============================================== */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} style={{ backgroundColor: 'white', padding: 30, borderRadius: 10, boxShadow: "1px 1px 1px #999999" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Name</p> */}
              <RHFTextField name="name" label="Write your name" />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Father Name</p> */}
              <RHFTextField name="father_name" label="Write your father name" />
            </Grid>

            <Grid item xs={12} md={4}>
              {/* <p style={{ fontSize: 8, color: 'gray' }}>CNIC Number</p> */}
              <RHFTextField name="cnic" label="CNIC (XXXXX-XXXXXXX-X)" />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Gender</p> */}
              <RHSelect name="gender" label="gender">
                {CATEGORY_OPTION.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Pre Approval Required</p> */}
              <RHSelect name="approvalRequired" label="Pre Approval Required">
                {PreRequiredApproval.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </RHSelect>
            </Grid>


            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Date of Birth</p> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="DOB"
                  inputFormat="MM/DD/YYYY"
                  value={DateOfBirth}
                  onChange={handleDate}
                  renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>City</p> */}
              <RHFTextField name="city" label="Write city name" />
            </Grid>

            {/* <Grid item xs={12} md={4} mb={2}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Organization</p>
              <RHSelect name="organization">
              <option>Select Organization</option>
                {allOrg.map((org) => (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_title}
                  </option>
                ))}
              </RHSelect>
            </Grid> */}

            {/* <Grid item xs={12} md={4} mb={2}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Role</p>
              <RHSelect name="role" label="role">
                {Roles.map((rol) => (
                  <option key={rol} value={rol}>
                    {rol}
                  </option>
                ))}
              </RHSelect>
            </Grid> */}

            {/* <Grid item xs={12} md={4} mb={2}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Organization Type</p>
              <RHSelect name="org_type" >
              <option>Select Organization Type</option>
                {allOrgCategories.map((rol) => (
                  <option key={rol.category_id} value={rol.category_id}>
                    {rol.category_title}
                  </option>
                ))}
              </RHSelect>
            </Grid> */}

            <Grid item xs={12} md={4}>
              <RHFTextField name="contact" label="Contact (03XXXXXXXXX)" />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Email/Username</p> */}

              <RHFTextField name="username" label="Email address / Username" />
            </Grid>


            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Contact Email</p> */}

              <RHFTextField name="contact_email" label="Contact Email" />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Password</p> */}

              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Password</p> */}

              <RHFTextField
                name="confPassword"
                label="Confirm Password"
                type={showConfPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfPassword(!showConfPassword)} edge="end">
                        <Iconify icon={showConfPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} mb={2}>
              <input
                style={styles}
                type="file"
                name="email"
                label="Email address"
                value={UserImage}
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12} md={6} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Current Salary </p> */}

              <RHFTextField name="current_salary" label="Current Salary" />
            </Grid>

            <Grid item xs={12} md={12} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Address </p> */}

              <RHFTextField name="address1" label="Address" />
            </Grid>

            <Grid item xs={12} md={3} mb={2}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

/* eslint-disable camelcase */
import * as React from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Avatar
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { AdminListHead, UserListToolbar, FirmListHead } from '../../sections/@dashboard/user';
import AddUser from './AddUser';
import DeleteOrganization from './DeleteOrganization';
import UpdateOrg from './UpdateOrg';
import { FormProvider, RHSelect } from '../../components/hook-form/index';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sno', label: 'S.No', alignRight: false },
  { id: 'org_title', label: 'FIRM', alignRight: false },
  { id: 'firmtype', label: 'FIRM TYPE', alignRight: false },
  { id: 'action', label: 'ACTION', alignRight: false },
];

// --------------------------------------------------
function Status(id) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
    </>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.organization.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ManageOrganizations(props) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('organization');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [org_title, setOrgTitle] = React.useState(false);
  const [org_category, setOrgCategory] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [allOrg, setAllOrg] = useState([]);
  const [deleteUserPopUp, setDeleteUserPopUp] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [orgId, setOrgId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [categories, setCategories ] = useState([]);
  const [allOrgCategories, setAllOrgCategories] = useState([])



  const history = useNavigate();

    //  ============================= image to base64 ======================================= //
    let base64code = ""
    let UserImage;
    const onChange = e => {
      const files = e.target.files;
      const file = files[0];
      getBase64(file);
      UserImage = file.name
    };
  
    const onLoad = fileString => {
      // console.log(fileString);
      base64code = fileString
    };
  
    const getBase64 = file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onLoad(reader.result);
      };
    };
  
    const styles = {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      height: 55,
      padding: 5,
      borderRadius: 7,
      width: '100%',
      paddingTop: 15,
    };
  
  
    //  ==================================== image to base64 ========================================= //

  // =========HANDLE cLOSE ============ //
  const handleUpdateClose = () => {
    setUpdatePopup(false);
  };

  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
    setOrgCategory(false)
    setOrgTitle(false)
  };
  const handleDeleteClose = () => {
    setDeleteUserPopUp(false);
  };


  // const onSubmitCategory = async () => {

  //   console.log(JSON.stringify({ 'category_title': org_category, 'image': base64code }))
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ 'category_title': org_category, 'image': base64code })
  //   };
  //   fetch('https://lms.firefly-techsolutions.com/services/web/api/add_organization_category', requestOptions)
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log(data)
  //       if (data.type === 'success') {
  //         window.scrollTo(0, 0)
  //         setMessage(data.message)

  //         setTimeout(() => {
  //           handleClose();
  //           setMessage('');
  //           refreshPage()
  //         }, 1000);

  //       } else {
  //         setMessage(data.message)
  //       }
  //     });
  // };

 

  const onSubmit = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        'org_category': org_category, 
        'org_title' : org_title ,
        'image' : base64code }),
    };
    
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_organization', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);

          setTimeout(() => {
            handleClose();
            refreshPage();
            setMessage('');
          }, 1000);
        } else {
          setMessage(data.message);
        }
      });

  };

  const getCategory = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organization_categories')
      .then((response) => response.json())
      .then((json) => setCategories(json.data));
  };

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };
  useEffect(() => {
    getAllOrg();
    getCategory();
  }, []);
  // const onClickEvent = () => {
  //   Data.push( { id: Data.length + 1, organization: enterValue})
  //   handleClose()
  //   console.log(Data)

  // }

  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allOrg.map((n) => n.org_title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allOrg.length) : 0;

  const filteredUsers = applySortFilter(allOrg, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//
  // ======================  Org Delete start =====================================//
  function refreshPage() {
    window.location.reload(false);
  }
  const navigate = useNavigate();

  const deleteOrg = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ org_id: deleteId }),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_organization', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            setMessage('')
            refreshPage()
          }, 1000);
          refreshPage();
        } else {
          setMessage(data.message);
        }
      });
  };

  // const handleClick = (org_id) => {
  //   navigate(`/admindashboard/admin/adminemployee/adminemployee=${encodeURIComponent(org_id)}`);
  // };
console.log(allOrg)
  // ======================  Org Delete end =====================================//

  return (
    <>
      <Page title="User">
        <Container>
          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Firms
            </Typography>
            <Button
              // onClick={() => setOpenPopup(true)}
              style={{ backgroundColor: 'white', border: '1px solid blue' }}
              component={RouterLink}
              to="/admindashboard/admin/adminemployee/addFirm"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Organization
            </Button>
          </Stack>
          {/* ======================= front Heading end =============================================== */}

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <FirmListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={allOrg.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { image, org_id, org_title,category_title, sdt, status } = row;
                      const isItemSelected = selected.indexOf(org_title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={org_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox" sx={{ pl: 5}}>{index + 1}</TableCell>

                          <TableCell>
                            <div style={{ display : 'flex' , flexDirection : 'row', alignItems: 'center'}}>
                            <Avatar sx={{ mr: 2 }} />
                            <Typography variant="subtitle2" noWrap>
                              {org_title}
                            </Typography>
                            </div>
                           
                          </TableCell>

                          <TableCell align="left"  width="35%">{category_title}</TableCell>
                     

                                   
                          <TableCell width="100%" align="left" flexDirection = 'column'>
                            <Button
                            component={RouterLink}
                            to="/admindashboard/admin/adminemployee/editFirm"
                            // state={}
                            >
                              Edit
                            </Button>
                            <Button
                                onClick={() => {
                                  setDeleteUserPopUp(true);
                                  setDeleteId(org_id);
                                }}
                                component={RouterLink}
                              to="#"
                              color='error'
                              
                              >
                              Delete
                            </Button>
                            <Button
                            //   onClick={() => {
                                //     setDeleteUserPopUp(true);
                                //     setUserId(user_id);
                                //   }}
                                component={RouterLink}
                                to="/admindashboard/admin/adminemployee/adminemployee"
                                state={{data: org_id}}
                                color='info'
                                >
                              Details
                            </Button>
                            
                            
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allOrg.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <AddUser openPopup={openPopup} setOpenPopup={setOpenPopup} title="Add Organization" onClick={handleClose}>
      {message ? (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#1a53ff',
              alignItems: 'center',
              // position: 'absolute',
              width: '100%',
              borderRadius: 10,
              marginBottom: 20,
              zIndex: 99
            }}
            variant="h6"

          >
            <Typography style={{ color: 'white' }} variant="h6" >
              {message}
            </Typography>
          </Typography>
        ) : null
        }


        <Stack spacing={2}>
          <FormControl sx={{ width: 600,}}>
            <Grid item xs={12} md={6} style={{marginBottom:12}}>
            <TextField
              style={{ width: 600 }}
              name="org_title"
              label="Add Organization"
              onChange={(ev) => setOrgTitle(ev.target.value)}
            />
            </Grid>

          <Grid item xs={12} md={6}>
            <TextField style={{ width: 600 }} name="category_title" label="Add Organization Category" onChange={(ev) => setOrgCategory(ev.target.value)} />
          </Grid>

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>Upload Image</p>
            {/* <input type="file" onChange={onChange} className="filetype" />               */}
            <input  style={styles} type="file" name="image" label="Upload Image" value={UserImage} onChange={onChange} />
          </Grid>
          </FormControl>

          {/* <FormControl sx={{ m: 1, width: 600 }}>
            <InputLabel id="demo-multiple-name-label">Select Type</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={org_category}
              onChange={handledropdown}
              input={<OutlinedInput label="Select Type" />}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              {categories.map((name) => (
                <MenuItem
                  key={name.category_id}
                  value={name.category_id}
                  style={getStyles(name, org_category, theme)}
                >
                  {name.category_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <Button disabled={!org_title  || !org_category ? true : null} fullWidth size="large" type="submit" variant="contained" onClick={onSubmit}>
            Add
          </Button>
        </Stack>
      </AddUser>
      {/* ====================================DELETE ORG ========================================== */}
      <AddUser
        openPopup={deleteUserPopUp}
        setOpenPopup={setDeleteUserPopUp}
        title="Delete Organization"
        onClick={handleDeleteClose}
        OrgId={orgId}
      >
        <DeleteOrganization
          ClickDelete={() => {
            deleteOrg();
            setDeleteUserPopUp(false);
          }}
          onClick={() => setDeleteUserPopUp(false)}
          text="Are you sure you want to delete this Organization?"
          buttondelete="Delete"
          buttonclose="Close"
        />
      </AddUser>

      {/* ====================================DELETE ORG ========================================== */}

      {/* ====================================UPDATE ORG ========================================== */}
      {/* <AddUser
        openPopup={updatePopup}
        setOpenPopup={setUpdatePopup}
        title="Update Organization"
        onClick={handleUpdateClose}
        OrgId={orgId}
      >
        <UpdateOrg allOrg={allOrg} id={orgId} handleClose={handleUpdateClose}/>
      </AddUser> */}
    </>
  );
}

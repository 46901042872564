// component
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

const getIconify = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfig = [

  {
    items :[
      {
        title: 'Home',
        path: '/admindashboard/adinmapp',
        icon: getIconify('eva:pie-chart-2-fill'),
      }
    ],
  },
  
  {
    items: [
      {
        title: 'Access Control',
        icon: getIconify('uil:processor'),
        children: [
          { title: 'Assign Role', path: '/admindashboard/assignrole' },
          { title: 'Manage Modules', path: '/admindashboard/managemodules' },
          { title: 'Manage Roles', path: '/admindashboard/manageroles' },
          { title: 'Manage Users', path: '/admindashboard/manageusers' },
          { title: 'Assign Modules', path: '/admindashboard/assignmodules' },
        ],
      },
    ]
  },

  {
    items: [
      {
        title: 'Admin/HR',
        icon: getIconify('dashicons:admin-users'),
        children: [
          { title: 'Manage User', path: '/admindashboard/admin/hr/manageuser' },
          { title: 'Manage Organizations', path: '/admindashboard/admin/hr/manageorganization' },
   
        ],
      },
    ]
  },
  {
    items: [
      {
        title: 'Applications',
        icon: getIconify('dashicons:admin-users'),
        children: [
          { title: 'Pending', path: '/admindashboard/admin/application/pendingapplication' },
          { title: 'Approval', path: '/admindashboard/admin/application/approvalapplication' },   
        ],
      },
    ]
  },


];

export default navConfig;

/* eslint-disable camelcase */
import  React, {useEffect,useState} from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate, Link } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Status from './Status';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,

} from '../../sections/@dashboard/user';

// mock
import AddUser from './AddUser';
import AddUserForm from './AddUserForm';
import ImportUser from './ImportUser';
import DeleteUserPopUp from './DeleteUserPopUp';
import EditUsers from './EditUsers'
// ----------------------------------------------------------------------

  const TABLE_HEAD = [
  { id: 'id', label: '', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'fname', label: 'FATHER/HUSBAND NAME', alignRight: false },
  { id: 'cnic', label: 'CNIC', alignRight: false },
  { id: 'organization', label: 'ORGANIZATION', alignRight: false },
  { id: 'createdAt', label: 'DATE OF BIRTH', alignRight: false },
  { id: 'address', label: 'ADDRESS', alignRight: false },
  { id: 'action', label: 'ACTION', alignRight: false },
];

// ----------------------------------------------------------------------

// // --------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ManageUsers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [importPopUp, setImportPopUp] = useState(false);
  const [deleteUserPopUp, setDeleteUserPopUp] = useState(false);
  const [getAllUsers, setGetAllUsers] = useState([])
  const [userId, setUserId] = useState('')


  const getAllModule = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_users')
      .then((response) => response.json())
      .then((json) => setGetAllUsers(json.data));
  };
  useEffect(() => {
    getAllModule()
  }, [getAllUsers]);
// console.log(getAllUsers)   

  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleImportClose = () => {
    setImportPopUp(false);
  };

  const handleDeletePopUp = () => {
    setDeleteUserPopUp(false);
  };



  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getAllUsers.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getAllUsers.length) : 0;
  const filteredUsers = applySortFilter(getAllUsers, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//

  const [popup, setPopUp] =useState(false)


  const handleUpdateClose = () => {
    setPopUp(false);
  };


  return (
    <>
      <Page title="User">
        <Container>
          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manage Users
            </Typography>
            <div>
             
              <Button
                variant="outlined"
                component={RouterLink}
                to="/admindashboard/admin/hr/addNewUser"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add User
              </Button>
            </div>
          </Stack>
          {/* ======================= front Heading end =============================================== */}

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table style={{ width: '100%' }}>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={getAllUsers.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { user_id, name, father_name, organization, cnic, avatarUrl, address, date_of_birth, gender,city,contact,image,user_type,sdt,pre_approval_required,username } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={user_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell  padding="checkbox" />
                          

                          <TableCell  align="left" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell width="20%" align="left">
                            {father_name}
                          </TableCell>

                          <TableCell width="20%" align="left">
                            {cnic}
                          </TableCell>
                          <TableCell width="10%" align="left">
                            {organization}
                          </TableCell>

                          {/* <TableCell align="center">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                          <TableCell width="30%" align="center">
                            {date_of_birth ? date_of_birth.substr(0,10) : null }
                          </TableCell>
                          <TableCell align="center">{address}</TableCell>

                          <TableCell width="60%" align="left" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                              component={RouterLink}
                              onClick={()=>{
                                setPopUp(true)
                                setUserId(user_id)
                                // console.log(user_id )
                                }}
                              to='#'
                              style={{ margin: 12 }}
                            >
                              Edit
                            </Button>
                            <Button onClick={() => {
                              setDeleteUserPopUp(true) 
                              setUserId(user_id) }} 
                              component={RouterLink} 
                              to="#">
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getAllUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>

      
      <AddUser openPopup={openPopup} setOpenPopup={setOpenPopup} title="Add User" onClick={handleClose}>
        <AddUserForm />
      </AddUser>
      <AddUser openPopup={importPopUp} setOpenPopup={setImportPopUp} title="Import User" onClick={handleImportClose}>
        <ImportUser />
      </AddUser>
      <AddUser
        openPopup={deleteUserPopUp}
        setOpenPopup={setDeleteUserPopUp}
        title="Delete User"
        onClick={handleDeletePopUp}
      >
  
        <DeleteUserPopUp id={userId} onClick={()=> setDeleteUserPopUp(false)} text="Are you sure you want to delete this User?" buttondelete= "Delete" buttonclose="Close" />
      </AddUser>
      <AddUser openPopup={popup} setOpenPopup={setPopUp} title="Update User" onClick={handleUpdateClose}>
        <EditUsers getAllUsers={getAllUsers} id={userId} onClose={handleUpdateClose} />
      </AddUser>
    </>
  );
}

/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Stack, Grid, Button, Typography } from '@mui/material';
import { FormProvider, RHFTextField } from '../../components/hook-form/index';

// ----------------------------------------------------------------------

export default function RejectApplicationPopUp({ onClick, id, buttondelete, buttonclose,  ClickDelete, applicationId}) {
const [ message, setMessage ] = useState('');
    const LoginSchema = Yup.object().shape({
        reason: Yup.string().required('Reason is required'),
        
      });
    
      const defaultValues = {
        reason: '',
        
        remember: true,
      };
    
      const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
      });
    
      const {
        handleSubmit,
        formState: { isSubmitting },
      } = methods;

      console.log(applicationId)
    
      const onSubmit = async (data) => {
        const sendingData = {
            
            user_id : id,
            reason : data.reason
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sendingData),
          };
          fetch('https://lms.firefly-techsolutions.com/services/web/api/reject_user_application', requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.type === 'success') {
                window.scrollTo(0, 0);
                setMessage(data.message);
                setTimeout(() => {
                  setMessage('');
                  onClick()
                }, 1000);
              } else {
                setMessage(data.message);
              }
              console.log(data);
            });
      }
  return (
    <Stack spacing={2}>
          { message ? (
            <Typography style={{ display: 'flex' , justifyContent: 'center', backgroundColor: '#ff1a1a', alignItems : "center",   position: 'absolute', width: '90%', borderRadius: 10}} variant="h4" gutterBottom>
            <Typography style={{ color: 'white'   }} variant="h6" gutterBottom>
            {message}
          </Typography>
          </Typography>
        ) : null }
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} md={12}>
      <RHFTextField name="reason" label="Please give the reason..." multiline rows={4}/>
      </Grid>
        <Grid item xs={12} md={12}>
          <Button style={{ height: 40 }}  fullWidth size="large" loading={isSubmitting} type="submit" variant="outlined">
            {buttondelete}
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            style={{ height: 40 }}
            onClick={onClick}
            color="error"
            fullWidth
            size="large"
            variant="outlined"
          >
            {buttonclose}
          </Button>

        </Grid>

      </Grid>
      </FormProvider>

    </Stack>
  );
}

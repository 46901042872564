import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import FirmDashboard from './pages/Firm/FirmDashboard';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminDashboardLayout from './pages/Admin'
import FirmDashboardLayout from './pages/Firm';
import Application from './pages/Application'
import AssignRole from './pages/Admin/AssignRole';
import ManageModules from './pages/Admin/ManageModules'; 
import ManageRoles from './pages/Admin/ManageRoles';
import ManageUsers from './pages/Admin/ManageUsers'
import AssignModules from './pages/Admin/AssignModules';
import ManageUser from './pages/Admin/ManageUser';
import ManageOrganization from './pages/Admin/ManageOrganization'
import AddUser from './pages/Admin/AddUser'
import PendingApplication from './pages/Admin/PendingApplication';
import ApprovalApplication from './pages/Admin/ApprovalApplication';
import AddApplication from './pages/Admin/AddApplication'
import ManageOrganizationCategory from './pages/Admin/ManageOrganizationCategory'
import FirmApplication from './pages/Firm/FirmApplication'
import UploadEmployee from './pages/Admin/UploadEmployee';
import AdminEmployee from './pages/Admin/AdminEmployee';
import GetAllFirms from './pages/Admin/GetAllFirms';
import PendingFirmApplication from './pages/Firm/PendingFirmApplication';
import ApprovalFirmApplication from './pages/Firm/ApprovalFirmApplication';
import AdminApplication from './pages/Admin/AdminApplication';
import AddFirmForm from './pages/Admin/AddFirmForm';
import EmployeeList from './pages/Firm/EmployeesList';
import UploadEmployeeFirm from './pages/Firm/UploadEmployeeFirm';
import FirmHomeDashboard from './pages/Firm/FirmHomeDashboard';
import EditEmployee from './pages/Firm/EditEmpolyee';
import EmployeeDetails from './pages/Firm/EmployeeDetails';
import UpdateOrg from './pages/Admin/UpdateOrg';
import Add from './pages/Admin/Add'
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp />},
        { path: 'user', element: <User />},
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'user/form', element: <Application /> }
      ],
    },
    {
      path: '/admindashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'adminapp', element: <DashboardApp /> },
        { path: 'assignrole', element: <AssignRole /> },
        { path: 'managemodules', element: <ManageModules /> },
        { path: 'manageroles', element: <ManageRoles /> },
        // { path: 'manageusers', element: <ManageUsers /> },
        { path: 'assignmodules', element: <AssignModules /> },
        { path: 'manageuser', element: <ManageUser /> },
        { path: 'manageuser/addUser', element: <AddUser /> },

      ],
    },

    {
      path: '/admindashboard/admin/hr',
      element: <DashboardLayout />,
      children: [
  
        { path: 'manageuser', element: <ManageUser /> },
        { path: 'manageorganization', element: <ManageOrganization /> },
        { path: 'manageorganizationcategory', element: <ManageOrganizationCategory /> },
        { path: 'addNewUser', element: <AddApplication /> },
        { path: 'add', element: <Add /> },

      ],
    },
    {
      path: '/admindashboard/admin/application',
      element: <DashboardLayout />,
      children: [
        { path: 'allapplication', element: <AdminApplication /> },
        { path: 'pendingapplication', element: <PendingApplication /> },
        { path: 'approvalapplication', element: <ApprovalApplication /> },


      ],
    },

    {
      path: '/admindashboard/admin/employee',
      element: <DashboardLayout />,
      children: [
        { path: 'uploademployee', element: <UploadEmployee /> },
      ],
    },

    {
      path: '/admindashboard/admin/adminemployee',
      element: <DashboardLayout />,
      children: [
        {path :'allfirms', element:<GetAllFirms />},
        {path: 'adminemployee', element: <AdminEmployee /> },
        {path: 'addFirm', element: <AddFirmForm /> },
        {path: 'editFirm', element: <UpdateOrg /> },

      ],
    },

    {
      path: '/firmdashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'firmapp', element: <FirmHomeDashboard /> },
        { path: 'firmapp/application', element: <FirmApplication /> },
        { path: 'firmapp/pendingapplication', element: <PendingFirmApplication /> },
        { path: 'firmapp/approvalapplication', element: <ApprovalFirmApplication /> },
        { path: 'firmapp/employeeList', element: <EmployeeList /> },
        { path: 'firmapp/uploadEmployee', element: <UploadEmployeeFirm /> },
        { path: 'firmapp/editEmployee', element: <EditEmployee /> },
        { path: 'firmapp/employeeDetails', element: <EmployeeDetails /> },
      ],
    
    },

    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

/* eslint-disable camelcase */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink , useNavigate} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserDetail, ShowUserDetail , Resubmit} from '../sections/@dashboard/user';

// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'date', label: 'DATE', alignRight: false },
  { id: 'amount', label: 'EXPECTED AMOUNT', alignRight: false },
  { id: 'installments', label: 'CURRENT SALARY', alignRight: false },
  { id: 'status', label: 'STATUS', alignRight: false },
  { id: 'action', label: 'ACTION' },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupReSubmit, setOpenPopupReSubmit] = useState(false)
  const [userData, setUserData] = useState([])

  // ====================== ASCENDING Desending =====================================//

  const items = true
  let UserId
  let UserName
  let UserImage
  if (items) {
    const items = JSON.parse(localStorage.getItem('data'));
    console.log(items)
    UserId = items.user.user_id
    UserName = items.user.name
    UserImage = items.user.image
  }

    const SetUserData = async () => {

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ "user_id": UserId })
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_user_applications', requestOptions)
      .then(response => response.json())
      .then(data => setUserData(data.data))

  };
  // console.log(userData)  
  useEffect(() => {
    SetUserData()
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

// ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleReSubmitClose = () => {
    setOpenPopupReSubmit(false);
  };
  const UserReSubmit = () =>{
    console.log('resubmit')
  }

  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;
  const filteredUsers = applySortFilter(userData, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//
  const navigate = useNavigate();

  

  return (
    <>
    <Page title="User">
      <Container>
        {/* ======================= front Heading =============================================== */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Application
          </Typography>
          <Button variant="contained" component={RouterLink}  to='/dashboard/user/form' startIcon={<Iconify icon="eva:plus-fill" />}>
            Create New Application
          </Button>
        </Stack>
        {/* ======================= front Heading end =============================================== */}


          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={userData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { user_id, expected_amount, status, sdt, avatarUrl, current_salary } = row;
                      const isItemSelected = selected.indexOf(UserName) !== -1;

                      const renderStatus = () => {
                        if(status === '0'){
                          return 'Pending'
                        }if(status === '1') {
                          return 'Approval Pending'
                        }if(status === '2') {
                          return 'Approved'
                        }if(status === '3') {
                          return 'Declined'
                        }if(status === '4') {
                          return 'In Process'
                        }if(status === '5') {
                          return 'Disbursed'
                        }
                       }

                     

                      return (
                        <TableRow
                          hover
                          key={user_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                     
                          <TableCell align="left">{user_id}</TableCell>


                          <TableCell align="center" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {UserName}
                              </Typography>
                            </Stack>
                          </TableCell>


                          <TableCell align="left">{sdt}</TableCell>

                          <TableCell align="left">{expected_amount}</TableCell>
                          <TableCell align="center">{current_salary}</TableCell>


                          {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                          <TableCell align="left">
                            <Label variant="ghost" color={(status === '3' && 'error') || (status === '0' && 'warning') || (status === '2' && 'success') || 'primary'}>
                              {sentenceCase(renderStatus())}
                            </Label>
                          </TableCell>

                          <TableCell >
                            {
                              (status === 'Approved' ? <Button onClick={() => setOpenPopup(true)} variant="contained" style={{ width: 105 }} component={RouterLink} to="#"> Details</Button> : undefined) ||
                              (status === 'Declined' ? <Button onClick={() => setOpenPopupReSubmit(true)}  variant="contained" component={RouterLink} to="#"> Re- Submit</Button> : undefined)
                            }
                          </TableCell>




                        </TableRow>

                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <UserDetail
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Repayment Plan"
        onClick={handleClose}
        
      >
        <ShowUserDetail />
      </UserDetail>

      <Resubmit
        openPopup={openPopupReSubmit}
        setOpenPopup={setOpenPopupReSubmit}
        title="Re-Submit Application"
        children="Are you sure you want to Re-Submit your Application?"
        onClick={handleReSubmitClose}
        onSubmit={UserReSubmit}
       
      />
      



    </>
  );
}
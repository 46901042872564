/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import * as React from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, Link } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Status from '../Admin/Status';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

// mock
import AddUser from '../Admin/AddUser';
// import AddUserForm from './AddUserForm';
import ImportUser from '../Admin/ImportUser';
import ActionOnForm from '../Admin/DeleteRole';
import RejectFormPopUp from '../Admin/RejectFormPopUp'
import FormDetail from '../Admin/FormDetail'
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'date', label: 'DATE', alignRight: false },
  { id: 'amountrequest', label: 'REQUESTED AMOUNT', alignRight: false },
  { id: 'currentsalary', label: 'CURRENT SALARY', alignRight: false },
  { id: 'installment', label: 'INSTALLMENT', alignRight: false },
  { id: 'status', label: 'STATUS', alignRight: false },
  { id: 'details', label: 'DETAILS', alignRight: false },
  { id: 'action', label: 'ACTION', alignRight: false },
];

// ----------------------------------------------------------------------

// --------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PendingFirmApplication() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [importPopUp, setImportPopUp] = useState(false);
  const [ApproveApplicationPopUp, setApproveApplicationPopUp] = useState(false);
  const [ detailsForm , setDetailForm] = useState(false)
  const [RejectApplicationPopUp, setRejectApplicationPopUp] = useState(false);
  const [message, setMessage] = React.useState('');
  const [getAllApplication, setGetAllApplication] = useState([]);
  const [userId, setUserId] = useState('');
  const [applicationId, setApplicationId] = useState('');

  const loggedData = JSON.parse(localStorage.getItem('data'));

  const AllApplication = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_applications')
      .then((response) => response.json())
      .then((json) => {
        const pendingFilter = json.data.filter(app=> app.status === '0') 
        setGetAllApplication(pendingFilter.filter(app=> app.org_id === loggedData.user.org_id))
      });
  };
  useEffect(() => {
    AllApplication();
  }, [getAllApplication]);

  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleImportClose = () => {
    setImportPopUp(false);
  };

  const handleApprovePopUp = () => {
    setApproveApplicationPopUp(false);
  };

  const handleDetailForm = () => {
    setDetailForm(false);
  };

  const handleRejectPopUp = () => {
    setRejectApplicationPopUp(false);
  };

  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getAllApplication.map((n) => n.name);
      setSelected(newSelecteds);
      console.log(newSelecteds)
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getAllApplication.length) : 0;
  const filteredUsers = applySortFilter(getAllApplication, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//

  const submitApproval = () => {
    const objData = {
      user_id: userId,
      application_id: applicationId,
      status: '2',
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(objData),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/change_application_status', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
          }, 1000);
        } else {
          setMessage(data.message);
        }
        console.log(data);
      });
  };
  return (
    <>
      <Page title="User">
        <Container>
          {message ? (
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#b3ffb3',
                alignItems: 'center',
                position: 'absolute',
                width: '70%',
                borderRadius: 10,
              }}
              variant="h4"
              gutterBottom
            >
              <Typography style={{ color: 'green' }} variant="h4" gutterBottom>
                {message}
              </Typography>
            </Typography>
          ) : null}

          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Pending Applications
            </Typography>
          </Stack>
          {/* ======================= front Heading end =============================================== */}

          <Card style={{ width: '100%' }}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table style={{ width: '100%' }}>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={getAllApplication.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        user_id,
                        name,
                        sdt,
                        current_salary,
                        expected_amount,
                        status,
                        installment,
                        pre_approval_required,
                        application_id
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={user_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          style={{ width: '100%' }}
                        >
                          <TableCell width="10%" align="left">
                            {name}
                          </TableCell>

                          <TableCell width="15%" align="left">
                            {sdt.substr(0, 10)}
                          </TableCell>

                          <TableCell width="10%" align="left">
                            {expected_amount}
                          </TableCell>
                          <TableCell width="10%" align="left">
                            {current_salary}
                          </TableCell>

                          {/* <TableCell align="center">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                          <TableCell width="10%" align="center">
                            {installment}
                          </TableCell>
                          <TableCell width="15%" align="center">
                            {status === '0' ? (<Typography style={{ backgroundColor: '#F7D668', borderRadius: 7, color: 'white', fontWeight: 'bold'}}> Pending</Typography>) : status === '1' ? (
                              <Typography style={{ backgroundColor: ' #dfff80', borderRadius: 7, color: 'white', fontWeight: 'bold'}}>Approval Pending</Typography>
                            ) : status === '2' ? (<Typography style={{ backgroundColor: '#5bd75b', borderRadius: 7, color: 'white', fontWeight: 'bold'}}> Approved</Typography>) :
                               status === '3' ? (
                              <Typography style={{ backgroundColor: '#ff1a1a', borderRadius: 7, color: 'white', fontWeight: 'bold'}}> Declined</Typography>
                            ) : status === '4' ? (<Typography style={{ backgroundColor: '#33ffff', borderRadius: 7, color: 'white', fontWeight: 'bold'}}> In Process</Typography>) : status === '5' ? (
                              <Typography style={{ backgroundColor: '#3399ff', borderRadius: 7, color: 'white', fontWeight: 'bold'}}> Disbursed</Typography>
                            ) : null}
                          </TableCell>
                               
                            <TableCell width="10%" align="left" style={{ flexDirection: 'row' }}>
                              <Button
                                  onClick={() => {
                                    setDetailForm(true);
                                    setApplicationId(application_id);
                                  }}
                                  component={RouterLink}
                                  to="#"
                                >
                                  Details
                                </Button> 
                                </TableCell>
                               
                          <>
                            {pre_approval_required === '1' ? null :  status === '0' ? (
                              <TableCell width="80%" align="left" style={{ flexDirection: 'row' }}>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setApproveApplicationPopUp(true);
                                    setUserId(user_id);
                                    setApplicationId(application_id);

                                  }}
                                  component={RouterLink}
                                  to="#"
                                >
                                  Approve
                                </Button>
                                <Button
                                   color="error"
                                   variant="outlined"
                                  style={{ marginLeft: 12 }}
                                  onClick={() => {setRejectApplicationPopUp(true); setUserId(user_id); setApplicationId(application_id)}}
                                  component={RouterLink}
                                  to="#"
                                >
                                  Reject
                                </Button>
                             
                              </TableCell>
                            ) : null}


                          </>

                       
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getAllApplication.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>

      <AddUser openPopup={openPopup} setOpenPopup={setOpenPopup} title="Add User" onClick={handleClose}>
        <ActionOnForm
          onClick={() => setOpenPopup(false)}
          text="Are you sure you want to forward this application for approval?"
          buttondelete="Request"
          buttonclose="Close"
        />
      </AddUser>

      <AddUser openPopup={importPopUp} setOpenPopup={setImportPopUp} title="Import User" onClick={handleImportClose}>
        <ImportUser />
      </AddUser>

      <AddUser
        openPopup={ApproveApplicationPopUp}
        setOpenPopup={setApproveApplicationPopUp}
        title="Approve Application"
        onClick={handleApprovePopUp}
      >
        <ActionOnForm
          ClickDelete={() => {
            setApproveApplicationPopUp(false);
            submitApproval();
          }}
          text="Are you sure you want to approve this Application?"
          buttondelete="Approve"
          buttonclose="Close"
        />
      </AddUser>

      <AddUser
        openPopup={detailsForm}
        setOpenPopup={setDetailForm}
        title="Details"
        onClick={handleDetailForm}
      >
        <FormDetail
          data={getAllApplication}
          id = {applicationId}
          ClickDelete={() => {
            setDetailForm(false);
          }}
          buttondelete="Approve"
          buttonclose="Close"
        />
      </AddUser>

      <AddUser
        openPopup={RejectApplicationPopUp}
        setOpenPopup={setRejectApplicationPopUp}
        title="Reject Application"
        onClick={handleRejectPopUp}
      >
        <RejectFormPopUp
          onClick={() => setRejectApplicationPopUp(false)}
          text="Are you sure you want to reject this Application?"
          buttondelete="Reject"
          buttonclose="Close"
          id = {userId}
          applicationId={applicationId}
        />
      </AddUser>
    </>
  );
}

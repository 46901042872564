import React from 'react';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  Divider,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  List,
  ListItem,
  Avatar,
} from '@mui/material';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';

const EmployeeDetails = () => {
  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 3 }}>
        <Grid container spaceing={0}>
          <Grid item xs={12} md={2}>
            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
              <img
                style={{ borderRadius: '50%' }}
                height={150}
                width={150}
                src="https://w7.pngwing.com/pngs/247/564/png-transparent-computer-icons-user-profile-user-avatar-blue-heroes-electric-blue.png"
                alt=""
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography gutterBottom variant="subtitle1">
              Muhammad Asif
            </Typography>
            <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <WorkOutlineOutlinedIcon style={{ fontSize: '15px' }} />
              <Typography sx={{ pl: 1 }} variant="caption">
                abc1234@gmail.com
              </Typography>
              <br />
            </Stack>
            <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <MailOutlineOutlinedIcon style={{ fontSize: '15px' }} />
              <Typography sx={{ pl: 1 }} variant="caption">
                FireFly Tech-Solutions Limited
              </Typography>
              <br />
            </Stack>
            <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <PinDropOutlinedIcon style={{ fontSize: '15px' }} />
              <Typography sx={{ pl: 1 }} variant="caption">
                G-9/1, IT Center, Islamabad
              </Typography>
              <br />
            </Stack>
            <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <LocalPhoneOutlinedIcon style={{ fontSize: '15px' }} />
              <Typography sx={{ pl: 1 }} variant="caption">
                +92 1234456789
              </Typography>
              <br />
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default EmployeeDetails;

/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm({moduleData}) {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const[ userData, setUserData ] = useState('');
  useEffect(()=> {
    const items = JSON.parse(localStorage.getItem('data'));
    if (items) {
     Object.values(items.modules).forEach(val => val.children ? setUserData([...userData, val.module_title]) : 'nothing here')
    }
  },[userData])
  const LoginSchema = Yup.object().shape({
    module_title: Yup.string().required('Module title is required'),
    module_url: Yup.string(),
    module_order: Yup.string().required('Module order is required'),
    icon_class: Yup.string(),
  });

  const defaultValues = {
    parent_module: '',
    module_title: '',
    module_url: '',
    module_order: '',
    icon_class: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (data) => {
    const objData = {
      icon_class: data.icon_class,
      module_order: data.module_order,
      module_title: data.module_title,
      module_url: data.module_url,
      parent_module:moduleData.filter(item=>item.label === data.parent_module ).map(item => item.id).toString()
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(objData),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_module', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            navigate('/admindashboard/adminapp', { replace: true });
          }, 1500);
        } else {
          setMessage(data.message);
        }
      });
  };

  // ----------------------------------------------

  const Employees = moduleData ? moduleData.filter(parentmodule => parentmodule.parent_module === '0'): []
 
  return (
    <>
      {message ? (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#b3ffb3',
            alignItems: 'center',
            // position: 'absolute',
            width: '100%',
            borderRadius: 10,
            marginBottom: 20,
          }}
          variant="h4"
          gutterBottom
        >
          <Typography style={{ color: 'green' }} variant="h6" gutterBottom>
            {message}
          </Typography>
        </Typography>
      ) : null}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Module Title</p>
              <RHFTextField name="module_title" label="Enter title" />
            </Grid>

            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Module Parent</p>
              <RHSelect name="parent_module" label="Select Parent" placeholder="Select Parent">
                <option value="" />
                {Employees.map((emp) => (
                  <option key={emp.label} value={emp.label}>
                    {emp.label}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Module Url</p>
              <RHFTextField name="module_url" label="Enter title" />
            </Grid>

            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>Module Order</p>

              <RHFTextField name="module_order" label="Enter Moule Order" />
            </Grid>

            <Grid item xs={12} md={12}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>Icon Class</p>

              <RHFTextField name="icon_class" label="Enter icon class" />
            </Grid>

            <Grid item xs={12} md={4}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

import * as Yup from 'yup';
import React, { useState, input } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('name is required'),
    S_employee: Yup.string().required('cnic is required'),
    password: Yup.string().required('city is required'),
    confPassword: Yup.string().required('contact is required'),
   
  });

  const defaultValues = {
    S_employee: '',
    username: '',
    password: '',
    confPassword: '',
    email: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const user = true;

  const onSubmit = async (data) => {
    console.log(data);
  };

  // ----------------------------------------------


  const Employees = ['Select One', 'User', 'HR'];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Select Employee</p>
            <RHSelect name="S_employee" label="employee">
              {Employees.map((emp) => (
                <option key={emp} value={emp}>
                  {emp}
                </option>
              ))}
            </RHSelect>
          </Grid>

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Username</p>
            <RHFTextField name="username" label="Write Username" />
          </Grid>

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Password</p>
            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>Confirm Password</p>

            <RHFTextField
              name="confPassword"
              label="Confirm Password"
              type={showConfPassword ? 'text' : 'Password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfPassword(!showConfPassword)} edge="end">
                      <Iconify icon={showConfPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
       
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
}

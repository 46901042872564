/* eslint-disable camelcase */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

// mock
import AddUser from './AddUser';
import AddModule from './AddModule';
import UpdateModules from './UpdateModules';
import DeleteModule from './DeleteModule';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'module_title', label: 'Module Title', alignRight: false },
  { id: 'parentmodule', label: 'Parent Module', alignRight: false },
  { id: 'moduleurl', label: 'Module Url', alignRight: false },
  { id: 'moduleorder', label: 'Module Order', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.label.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Datas() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('moduleTitle');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [updateopen, setUpdatePopup] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [otherData, setOtherData] = useState(null);
  const [deleteModulePopUp, setDeleteModulePopUp] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [complete, setComplete] = useState(false);

  const getAllModule = () => {
    // const moduleData = []
    const anyArray = [];
    let uniqueArray = [];

    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_modules')
      .then((response) => response.json())
      .then((json) => setOtherData(json.data))
      .then(() => Object.values(otherData).forEach((val) => {
        anyArray.push(val)
        if(val.children){
          val.children.map(child => {
            anyArray.push(child)
            uniqueArray = anyArray
            return null
          })
        } 
      }))
      .then(() => setModuleData(uniqueArray))
      .then(() => setComplete(true))
  };

  //   const items = JSON.parse(localStorage.getItem('data'));
// console.log(moduleData)
  // console.log(otherData)

  useEffect(() => {
   if(!complete){
    getAllModule();
  }
  }, [otherData, moduleData]);
  //  console.log(moduleData.map(item => item.label))
  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleUpdateClose = () => {
    setUpdatePopup(false);
  };

  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = moduleData.map((n) => n.label);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClose = () => {
    setDeleteModulePopUp(false);
  };
  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - moduleData.length) : 0;

  const filteredUsers = applySortFilter(moduleData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//
  const navigate = useNavigate();
  const [clickedId, setClickedId] = useState('');

  // ======================  Org Delete start =====================================//
  const deleteModule = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ module_id: deleteId }),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_module', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      });
  };

  // ======================  Org Delete end =====================================//

  return (
    <>
      <Page title="User">
        <Container>
          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manage Modules
            </Typography>
            <Button
              onClick={() => setOpenPopup(true)}
              variant="outlined"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create New Module
            </Button>
          </Stack>
          {/* ======================= front Heading end =============================================== */}

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={moduleData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, label, module_order, module_url, parent_module } = row;
                      const isItemSelected = selected.indexOf(label) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{label}</TableCell>

                          <TableCell align="center" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {parent_module}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell style={{ width: '20%' }}>{module_url}</TableCell>

                          <TableCell style={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}>
                            {module_order}
                          </TableCell>

                          <TableCell align="left">
                            <Button
                              onClick={() => {
                                setUpdatePopup(true);
                                setClickedId(id);
                              }}
                              style={{ border: '1px solid blue' }}
                              component={RouterLink}
                              to="#"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setDeleteModulePopUp(true);
                                setDeleteId(id);
                              }}
                              style={{ border: '1px solid blue', marginLeft: 12 }}
                              component={RouterLink}
                              to="#"
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={moduleData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <AddUser openPopup={openPopup} setOpenPopup={setOpenPopup} title="Add Module" onClick={handleClose}>
        <AddModule moduleData={moduleData}/>
      </AddUser>
      <AddUser openPopup={updateopen} setOpenPopup={setUpdatePopup} title="Update Module" onClick={handleUpdateClose}>
        <UpdateModules moduleData={moduleData} id={clickedId} />
      </AddUser>
      <AddUser
        openPopup={deleteModulePopUp}
        setOpenPopup={setDeleteModulePopUp}
        title="Delete Module"
        onClick={handleDeleteClose}
      >
        <DeleteModule
          ClickDelete={() => {
            deleteModule();
            setDeleteModulePopUp(false);
          }}
          onClick={() => setDeleteModulePopUp(false)}
          text="Are you sure you want to delete this Module ?"
          buttondelete="Delete"
          buttonclose="Close"
        />
      </AddUser>
    </>
  );
}

import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({

  id: sample([
    'emp-001',
    'emp-001',
  ]),

  avatarUrl: `/static/mock-images/avatars/avatar_${1}.jpg`,

  name: sample([
    'Muhammd Asif',
  ]),

  date:sample([
    '11-05-2022',
    '19-06-2022',
  ]),

  company: faker.company.companyName(),

  amount:sample([
    '12,000',
    '20,000',
  ]),

  installments:sample([
    '4',
    '6',
    '2',
    '6',
  ]),
 
  isVerified: faker.datatype.boolean(),

  status: sample(['InProgress', 'Approved', 'Declined']),

  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),

}));

export default users;

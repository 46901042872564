import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form/index';


export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;





  const onSubmit = async (data) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data).toLowerCase()
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/auth/login', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.message === "Invalid Username / Password") {
          setMessage('Username or Password is invalid')
        }

        if (data.data.user.user_type === "1") {
          localStorage.setItem('data', JSON.stringify(data.data));
          navigate('/admindashboard/adminapp', { replace: true })
        } else if (data.data.user.user_type === "3") {
          localStorage.setItem('data', JSON.stringify(data.data));
          navigate('/dashboard/app', { replace: true })


        } else if (data.data.user.user_type === "2") {
          localStorage.setItem('data', JSON.stringify(data.data));
          navigate('/firmdashboard/firmapp', { replace: true })

        } else {
          console.log('anything')
        }
      });


  };

  return (
    <>
      <Typography style={{ height: 25, marginBottom: 15, }}>
        {message ? (
          <Typography style={{ color: 'red', fontWeight: 'bold', backgroundColor: '#ffe6e6', borderRadius: 10, paddingLeft: 15, }}>
            {message}
          </Typography>
        ) : null}
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

        <Stack spacing={3}>
          <RHFTextField name="username" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} >
          <RHFCheckbox name="remember" label="Remember me" />
          {/* <Link variant="subtitle2" underline="hover" style={{color  :'#1A8058'}}>
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton fullWidth style={{backgroundColor:'#1A8058'}} size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </FormProvider>
    </>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, input, useEffect } from 'react';
import { Stack, Grid, Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
// ----------------------------------------------------------------------

export default function DeleteUserPopUp({ onClick, text, buttondelete, id, data, buttonclose, ClickDelete }) {
  const [formData, setFormData] = useState('');
  const getNewData = () => {
    data.map((d) => (d.application_id === id ? setFormData(d) : null));
  };
  useEffect(() => {
    getNewData();
  });
  return (
    <Stack spacing={2}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid style={{ display: 'flex' }} item xs={12} md={6}>
          <Typography style={{ fontWeight: 'bold', paddingRight: 3, width: 170 }}>Name : </Typography>
          <Typography> {formData.name}</Typography>
        </Grid>
        <Divider />
        <Grid style={{ display: 'flex' }} item xs={12} md={6}>
          <Typography style={{ fontWeight: 'bold', paddingRight: 3, width: 170 }}>Organization Type : </Typography>
          <Typography>
            {' '}
            <span> </span>
            {formData.org_type}
          </Typography>
        </Grid>
        <Grid style={{ display: 'flex' }} item xs={12} md={6}>
          <Typography style={{ fontWeight: 'bold', paddingRight: 3, width: 170 }}>Organization : </Typography>
          <Typography> {formData.org_title}</Typography>
        </Grid>

        <Grid style={{ display: 'flex' }} item xs={12} md={6}>
          <Typography style={{ fontWeight: 'bold', paddingRight: 3, width: 170 }}>Requested Amount : </Typography>
          <Typography>
            {' '}
            <span> </span>
            {formData.expected_amount}
          </Typography>
        </Grid>

        <Grid style={{ display: 'flex' }} item xs={12} md={6}>
          <Typography style={{ fontWeight: 'bold', paddingRight: 3, width: 170 }}>Apply Date : </Typography>
          <Typography>
            {' '}
            <span> </span>
            {formData.sdt}
          </Typography>
        </Grid>
        <Grid style={{ display: 'flex' }} item xs={12} md={6}>
          <Typography style={{ fontWeight: 'bold', paddingRight: 3, width : 170 }}>Status : </Typography>
          {formData.status === '0' ? (
            <Typography align='center' style={{ width: 100, backgroundColor: 'yellow', borderRadius: 7, color: 'white', fontWeight: 'bold' }}>
              Pending
            </Typography>
          ) : formData.status === '1' ? (
            <Typography align='center' style={{ width: 100, backgroundColor: ' #dfff80', borderRadius: 7, color: 'white', fontWeight: 'bold' }}>
              Approval Pending
            </Typography>
          ) : formData.status === '2' ? (
            <Typography align = 'center' style={{  width: 100, backgroundColor: '#5bd75b', borderRadius: 7, color: 'white', fontWeight: 'bold' }}>
              {' '}
              Approved
            </Typography>
          ) : formData.status === '3' ? (
            <Typography align='center' style={{ width: 100, backgroundColor: '#ff1a1a', borderRadius: 7, color: 'white', fontWeight: 'bold' }}>
              {' '}
              Declined
            </Typography>
          ) : formData.status === '4' ? (
            <Typography align='center' style={{ width: 100, backgroundColor: '#33ffff', borderRadius: 7, color: 'white', fontWeight: 'bold' }}>
              {' '}
              In Process
            </Typography>
          ) : formData.status === '5' ? (
            <Typography align='center' style={{ width: 100, backgroundColor: '#3399ff', borderRadius: 7, color: 'white', fontWeight: 'bold' }}>
              {' '}
              Disbursed
            </Typography>
          ) : null}
        </Grid>
        {formData.declined_reason ? (
             <Grid style={{ display: 'flex' }} item xs={12} md={12}>
             <Typography style={{ fontWeight: 'bold', paddingRight: 3, width: 170 }}>Declined Reason : </Typography>
             <Typography>
               {' '}
               <span> </span>
               {formData.declined_reason}
             </Typography>
           </Grid>
         ) : null}

        <Grid item xs={12} md={2} align='center'>
          <Button
            style={{ height: 40 }}
            onClick={ClickDelete}
            color="error"
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
          >
            {buttonclose}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

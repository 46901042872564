import * as Yup from 'yup';
import React, { useState, input } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm() {
  

 
//  -----------------------------------------------------------
 
 

 
  // ---------------------------------------------------------
 
  // const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    cnic: Yup.string().required('CNIC is required'),
    
   
  });

  const defaultValues = {
    
    cnic: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const user = true;

  const onSubmit = async (data) => {
    console.log(data);
  };

  // ----------------------------------------------


  const Employees = ['Select One', 'User', 'HR'];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>

        <Grid container spacing={2}>
       
          <Grid item xs={12} md={12}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>CNIC</p>
            <RHFTextField name="cnic" label="XXXXX-XXXXXXX-X" />
          </Grid>

        
          <Grid item xs={12} md={12}>
            <LoadingButton fullWidth size="large" type="submit" variant="outlined" loading={isSubmitting}>
              Import
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
}

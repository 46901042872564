/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import React, { useEffect, input, useState } from 'react';
import { Link as RouterLink, useNavigate ,useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm({getAllUsers, id, onClose}) {
  const navigate = useNavigate();

  
  const [showPassword, setShowPassword] = useState(false);
  const [DateOfBirth, setDateOfBirth] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [message, setMessage] = React.useState('');
  const[Close, setClose] = useState(false)
  
  const handleClose = () => {
    setClose(false)
  }

  const handleDate = (newValue) => {
    setDateOfBirth(newValue);
  };

const [ editData, setEditData ] = useState('');
const dataFunction = () => {
  getAllUsers.map((d) => {
        const singleEdit = d.user_id === id ? setEditData(d) : null
        return singleEdit
    } )
}


// console.log(editData)    
useEffect(() =>{
  dataFunction()
})

  // ---------------------------------------------------------
  const [image, setImage] = React.useState('');
  const handlePhoto = (event) => {
    setImage(event.target.value);
    console.log(image);
  };

//   console.log(location.state.name)
  // const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    name: Yup.string(),
    password: Yup.string(),
    father_name: Yup.string(),
    type: Yup.string(),
    city: Yup.string(),
    role: Yup.string(),
    address1: Yup.string(),
  });

  const defaultValues = {
    username: '',
    password: '',
    name: '',
    father_name: '',
    org_id: '',
    type: '',
    cnic: '',
    city: '',
    contact: '',
    organization: '',
    gender: '',
    country: '',
    role: '',
    address1: '',
    approvalRequired: '',
    remember: true,
    contact_email :'',
    current_salary :'',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {handleSubmit, setValue, formState: { isSubmitting },
  } = methods;

 

  function refreshPage() {
    window.location.reload(false);
  }
  
 
  const onSubmit = async (data) => {
    console.log(editData.org_id)
    const dataObject = {
      'user_id' :id,
      'username': userName === '' ? editData.username : userName,
      'name': userTitle === '' ? editData.name : userTitle,
      'father_name': fatherName === '' ? editData.father_name : fatherName,
      'org_id': data.organization === '' ? editData.org_id : data.organization,
      'user_type': userRole === '' ? editData.user_type : userRole === 'Admin' ? '1' : userRole === 'Employee' ? '3' : userRole === 'Firm' ? '2' : null,
      'cnic' : userCnic === '' ? editData.cnic : userCnic,
      'city': userCity === '' ? editData.city : userCity,
      'organization': data.organization === '' ? editData.org_id : data.organization,
      'date_of_birth': DateOfBirth === '' ? editData.date_of_birth : DateOfBirth,
      'gender' : userGender === '' ? editData.gender : userGender,
      'role': userRole === '' ? editData.role_id : userRole,
      'contact': userContact === '' ? editData.contact : userContact,
      'image': dataImage === '' ? editData.image : dataImage,
      'address' : userAddress === '' ? editData.address : userAddress,
      'password' : showPassword === ''? editData.password : showPassword,
      'role_id' : userRole === '' ? editData.user_type : userRole === 'Admin' ? '1' : userRole === 'Employee' ? '3' : userRole === 'Firm' ? '2' : null,
      'pre_approval_required' : approvalRequired === '' ? editData.pre_approval_required : approvalRequired=== 'Yes' ? '1' : '0',
      'email': userEmail === '' ? editData.email : userEmail,
      'salary': userCurrentSalary === ''? editData.salary : userCurrentSalary,
      'org_type' : data.org_type === '' ? editData.category_id : data.org_type
    }
    console.log(JSON.stringify(dataObject))
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataObject)
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/update_user', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
         if(data.type === 'success'){
          window.scrollTo(0, 0)
          setMessage(data.message)
          setTimeout( () => {handleClose();  setMessage(''); onClose()}, 1000);
         }else{
          setMessage(data.message)
         } 
         console.log(data)
        });
  };

  
  // ----------------------------------------------
 

  const CATEGORY_OPTION = ['Select One', 'Male', 'Female'];
  const PreRequiredApproval = ['Select One', 'Yes', 'No'];
  const Type = ['Select One', 'Admin', 'Applicant', 'Firm'];
  const Roles = ['Select One', 'Admin', 'Employee', 'Firm'];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
  // ----------------------------------------------

  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };
const [userTitle, setUserTitle] = useState('');
const [fatherName, setFatherName] = useState('');
const [userCnic, setUserCnic] = useState('');
const [userGender, setUserGender] = useState('');
const [userCity, setUserCity] = useState('');
const [userRole, setUserRole] = useState('');
const [userContact, setUserContact] = useState('');
const [userName, setUserName] = useState('');
const [userEmail, setUserEmail] = useState('');
const [userCurrentSalary, setCurrentSalary] = useState('');
const [userAddress, setUserAddress] = useState('');
const [approvalRequired, setApprovalRequired] = useState('');
const[dataImage, setDataImage] = useState("")
const [ allOrgCategories, setAllOrgCategories] = useState([])
const [ allOrg, setAllOrg] = useState([])
const [ catLabel, setCatLabel] = useState('')

    //  ============================= image to base64 ======================================= //
    let UserImage;
    const onChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
    UserImage =file.name
  };
  
  const onLoad = fileString => {
    console.log(fileString);
    setDataImage(fileString)
  };
  
  const getBase64 = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  // console.log(dataImage)
  
  const getOrgCategories = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organization_categories')
      .then((response) => response.json())
      .then((json) => {
        setAllOrgCategories(json.data); 
        return json.data
      })
      .then((data) =>{ setCatLabel(data[0].category_title)});
  };

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };
  useEffect(() => {
    getOrgCategories()
    getAllOrg()
  }, []);
  //  ==================================== image to base64 ========================================= //
 

const label = () =>{
  if(editData.role_id === '1'){
    return 'Admin'
  }if (editData.role_id === 2) {
    return 'User'
  }if(editData.role_id === '3'){
    return 'Firm'
  }
}


  return (
    <>
      {/* ======================= front Heading =============================================== */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        { message ? (
            <Typography style={{ display: 'flex' , justifyContent: 'center', backgroundColor: '#1a53ff', alignItems : "center",   position: 'absolute', width: '95%', borderRadius: 10, zIndex: 99}} variant="h6" >
            <Typography style={{ color: 'white'   }} variant="h6" >
            {message}
          </Typography>
          </Typography>
        ) : null }
        
        <Typography variant="h4" gutterBottom>
          Application Form
        </Typography>
        
      </Stack>
      {/* ======================= front Heading end =============================================== */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} style={{ backgroundColor: 'white', padding: 30, borderRadius: 10, boxShadow: "1px 1px 1px #999999" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Name</p> */}
              <TextField name="name" value={userTitle} onChange ={(text)=> setUserTitle( text.target.value)}  label={editData.name}/>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Father Name</p> */}
              <TextField name="father_name" value={fatherName} onChange ={(text)=> setFatherName( text.target.value)}  label={editData.father_name} />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>CNIC Number</p> */}
              <TextField name="cnic"  value={userCnic}  onChange ={(text)=> setUserCnic( text.target.value)} label={editData.cnic} />
            </Grid>

            
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Gender</p> */}
              <RHSelect name="gender"    value={userGender}  onChange ={(text)=> setUserGender( text.target.value)} label={editData.gender === '0' ? 'Male' : 'Female '}>
                {CATEGORY_OPTION.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              <RHSelect name="approvalRequired" label={editData.pre_approval_required} value={approvalRequired} onChange ={(text)=> setApprovalRequired( text.target.value)} >
                {PreRequiredApproval.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </RHSelect>
            </Grid>


            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Date of Birth</p> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={editData.date_of_birth}
                  inputFormat="MM/DD/YYYY"
                  value={DateOfBirth}
                  onChange={handleDate}
                  renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>City</p>
              <TextField  name="city" value={userCity} onChange ={(text)=> setUserCity( text.target.value)} label={editData.city} />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Organization</p>
              <RHSelect name="organization">
              <option>Select Organization</option>
                {allOrg.map((org) => (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_title}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Select Role</p>
              <RHSelect name="role" value={userRole} onChange={(text)=> setUserRole(text.target.value)} label={label()}>
                {Roles.map((rol) => (
                  <option key={rol} value={rol}>
                    {rol}
                  </option>
                ))}
              </RHSelect>   
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Organization Type</p> */}
              <RHSelect name="org_type">
              <option>Select Organization Type</option>
                {allOrgCategories.map((rol) => (
                  <option key={rol.category_id} value={rol.category_id}>
                    {rol.category_title}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Phone Number</p> */}
              <TextField name="contact" value={userContact} onChange ={(text)=> setUserContact( text.target.value)} label={editData.contact} />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Email/Username</p> */}

              <TextField name="username" value={userName}  onChange ={(text)=> setUserName( text.target.value)} label={editData.username} />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Contact Email</p> */}

              <TextField name="contact_email" value={userEmail} onChange ={(text)=> setUserEmail( text.target.value)} label={editData.email} />
            </Grid>

            {/* <Grid item xs={12} md={4} mb={2}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>Password</p>

              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Profile Photo</p> */}

              <input
                style={styles}
                type="file"
                name="email"
                label="Email address"
                value={UserImage}
                onChange={onChange}
              />
            </Grid>
            
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Cuurent Salary </p> */}

              <TextField style={{width:'100%'}} name="current_salary" value={userCurrentSalary} onChange ={(text)=> setCurrentSalary( text.target.value)} label={editData.salary} />
            </Grid>

            <Grid item xs={12} md={12} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Address </p>    */}

              <TextField style={{width:'100%'}}  name="address1" value={userAddress} onChange ={(text)=> setUserAddress( text.target.value)}  label={editData.address} />
            </Grid>

            <Grid item xs={12} md={3} mb={2}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

import * as Yup from 'yup';
import React, { useState, input, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
// ----------------------------------------------------------------------

export default function UpdateModules({moduleData, id, }) {
  const navigate = useNavigate();
const [ editData, setEditData ] = useState('');
const dataFunction = () => {

    moduleData.map((d) => {
        const singleEdit = d.module_id === id ? setEditData(d) : null
        return singleEdit
    } )
}

useEffect(() =>{
    dataFunction()
})
  const [message, setMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    module_title: Yup.string(),
    module_url: Yup.string(),
    module_order: Yup.string(),
    icon_class: Yup.string()
  });

  const defaultValues = {
    parent_module: '',
    module_title: '',
    module_url: '',
    module_order: '',
    icon_class: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  function refreshPage() {
    window.location.reload(false);
  }

  const onSubmit = async (data) => {
    const objectModuleData = {
        "parent_module": data.parent_module,
        "module_title": data.module_title,
        "module_url": data.module_url,
        "module_order": data.module_order,
        "icon_class": data.icon_class,
        "module_id" : id,
        "remember": true
    }
    console.log(JSON.stringify(objectModuleData));

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(objectModuleData),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/update_module', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            navigate('/admindashboard/managemodules', { replace: true });
          }, 1500);
          refreshPage()
        } else {
          setMessage(data.message);
        }
      });
  };

  // ----------------------------------------------

  // const Employees = ['Select One', 'Home', 'Access Control', 'Admin/HR', 'Applications'];
  const Employees = moduleData.filter(parentmodule => parentmodule.parent_module === '0')
  return (
    <>
      { message ? (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#b3ffb3',
            alignItems: 'center',
            // position: 'absolute',
            width: '100%',
            borderRadius: 10,
            marginBottom: 20
          }}
          variant="h4"
          gutterBottom
        >
          <Typography style={{ color: 'green' }} variant="h6" gutterBottom>
            {message}
          </Typography>
        </Typography>
      ) : null
}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Module Title</p>
              <RHFTextField  name="module_title" label={editData.module_title} />
            </Grid>

            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Module Parent</p>
              <RHSelect  name="parent_module" label="Select Parent">
                {Employees.map((emp) => (
                  <option key={emp.label} value={emp.label}>
                    {emp.label}
                  </option>
                ))}
              </RHSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Module Url</p>
              <RHFTextField  name="module_url" label={editData.module_url} />
            </Grid>

            <Grid item xs={12} md={6}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>Module Order</p>

              <RHFTextField  name="module_order" label={editData.module_order} />
            </Grid>

            <Grid item xs={12} md={12}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>Icon Class</p>

              <RHFTextField  name="icon_class" label={editData.icon_class} />
            </Grid>

            <Grid item xs={12} md={4}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

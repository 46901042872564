/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import React, { useState, input, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
// ----------------------------------------------------------------------

export default function UpdateModules({allRole, RoleId }) {
  const navigate = useNavigate();

  console.log(RoleId)
const [ roleSingleData, setRoleSingleData ] = useState('');
const dataFunction = () => {
    allRole.map((d) => {
        const singleEdit = d.role_id === RoleId ? setRoleSingleData(d) : null
        return singleEdit
    } )
}

useEffect(() =>{
    dataFunction()
})
  const [message, setMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    role_title: Yup.string(),
      });

  const defaultValues = {
    role_title: '',
 
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  function refreshPage() {
    window.location.reload(false);
  }

  const onSubmit = async (data) => {
    console.log(data.role_title)
//     console.log(JSON.stringify(objectModuleData));

    const requestOptions = {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'role_title' : data.role_title, 'role_id' : RoleId}),   
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/update_role', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            navigate('/admindashboard/manageroles', { replace: true });
          }, 1500);
        refreshPage()

        } else {
          setMessage(data.message);
        }
      });
  };

  // ----------------------------------------------


  return (
    <>
      { message ? (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#b3ffb3',
            alignItems: 'center',
            // position: 'absolute',
            width: '100%',
            borderRadius: 10,
            marginBottom: 20
          }}
          variant="h4"
          gutterBottom
        >
          <Typography style={{ color: 'green' }} variant="h6" gutterBottom>
            {message}
          </Typography>
        </Typography>
      ) : null
}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Role Name</p>
            <RHFTextField fullWidth  name="role_title"   label={roleSingleData.role_title} />
          </Grid>
 
  
          <Grid item xs={12} md={12} >
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Update
              </LoadingButton>
            </Grid>
          
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

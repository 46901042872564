/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import * as React from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { AdminListHead, UserListToolbar } from '../../sections/@dashboard/user';
import AddUser from './AddUser'
import DeleteCategoryPopup from './DeleteCategory'
import UpdateCategory from './UpdateCategory';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'category_title', label: 'CATEGORY', alignRight: false },
  { id: 'action', label: 'ACTION', alignRight: true },


];


// --------------------------------------------------
function Status(id) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(id)
  };


  return (
    <>
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.organization.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ManageOrganizationCategory() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('organization');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [org_category, setOrgCategory] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [allOrgCategories, setAllOrgCategories] = useState([])
  const [deleteUserPopUp, setDeleteUserPopUp] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [catId, setCatId] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [image, setImage] = useState('');
  const [file, setFile] = useState(null)

  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };

  // const handleCategoryImage = (event) => {
  //   setImage(event.target.value);
  //   console.log(image);
  // };



  // =========HANDLE cLOSE ============ //
  const handleUpdateClose = () => {
    setUpdatePopup(false);
  };



  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleDeleteClose = () => {
    setDeleteUserPopUp(false);
  };

  const onSubmitCategory = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'category_title': org_category, 'image': base64code })
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_organization_category', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.type === 'success') {
          window.scrollTo(0, 0)
          setMessage(data.message)

          setTimeout(() => {
            handleClose();
            setMessage('');
            refreshPage()
          }, 1000);

        } else {
          setMessage(data.message)
        }
      });
  };


  const getOrgCategories = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organization_categories')
      .then((response) => response.json())
      .then((json) => setAllOrgCategories(json.data));
  };
  useEffect(() => {
    getOrgCategories()
  }, []);
  // const onClickEvent = () => {
  //   Data.push( { id: Data.length + 1, organization: enterValue})
  //   handleClose()
  //   console.log(Data)

  // }

  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allOrgCategories.map((n) => n.org_title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allOrgCategories.length) : 0;
  const filteredUsers = applySortFilter(allOrgCategories, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//
  // ======================  Org Delete start =====================================//
  function refreshPage() {
    window.location.reload(false);
  }
  const navigate = useNavigate();




  const deleteOrg = async () => {

    console.log(JSON.stringify({ 'category_id': deleteId }));

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "category_id": deleteId }),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_organization_category', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            setMessage('')
            refreshPage()
            setDeleteUserPopUp(false)
          }, 1000);
        } else {
          setMessage(data.message)
        }

      });
  };

  //  ============================= image to base64 ======================================= //
  let base64code = ""
  let UserImage;
  const onChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
    UserImage = file.name
  };

  const onLoad = fileString => {
    console.log(fileString);
    base64code = fileString
  };

  const getBase64 = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  console.log(UserImage)

  //  ==================================== image to base64 ========================================= //





  // ======================  Org Delete end =====================================//

  return (
    <>


      <Page title="User">
        <Container>
          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Manage Organization Category
            </Typography>
            <Button
              onClick={() => setOpenPopup(true)}
              style={{
                backgroundColor: 'white',
                border: '1px solid blue'
              }}
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Organization Category
            </Button>
          </Stack>
          {/* ======================= front Heading end =============================================== */}

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <AdminListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={allOrgCategories.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { image, category_id, category_title, sdt, } = row;
                      const isItemSelected = selected.indexOf(category_title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={category_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox" />

                          <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={category_title} src={image} sx={{ mr: 2 }} />
                            <Typography variant="subtitle2" noWrap>
                              {category_title}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" />

                          <TableCell align="left" />

                          <TableCell width="80%" align="left" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                              component={RouterLink}
                              onClick={() => {
                                setUpdatePopup(true)
                                setCatId(category_id)
                              }}
                              to='#'
                              style={{ margin: 12 }}
                            >
                              Edit
                            </Button>
                            <Button onClick={() => { setDeleteUserPopUp(true); setDeleteId(category_id) }} component={RouterLink} to="#">
                              Delete
                            </Button>
                          </TableCell>


                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allOrgCategories.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>

      <AddUser
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Organization"
        onClick={handleClose}

      >
        {message ? (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#1a53ff',
              alignItems: 'center',
              // position: 'absolute',
              width: '100%',
              borderRadius: 10,
              marginBottom: 20,
              zIndex: 99
            }}
            variant="h6"

          >
            <Typography style={{ color: 'white' }} variant="h6" >
              {message}
            </Typography>
          </Typography>
        ) : null
        }
        <Stack spacing={3}>
          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Organization Category</p>
            <TextField style={{ width: 100 }} name="category_title" label="Add Organization Category" onChange={(ev) => setOrgCategory(ev.target.value)} />
          </Grid>

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>Upload Image</p>
            {/* <input type="file" onChange={onChange} className="filetype" />               */}
            <input style={styles} type="file" name="image" label="Upload Image" value={UserImage} onChange={onChange} />
          </Grid>

          <Grid item xs={12} md={4}>
            <Button fullWidth size="large" type="submit" variant="contained" onClick={onSubmitCategory} >
              Add
            </Button>

          </Grid>
        </Stack>


      </AddUser>
      {/* ====================================DELETE ORG ========================================== */}
      <AddUser
        openPopup={deleteUserPopUp}
        setOpenPopup={setDeleteUserPopUp}
        title="Delete Category"
        onClick={handleDeleteClose}
        CatId={catId}

      >

        <DeleteCategoryPopup
          ClickDelete={() => { deleteOrg(); setDeleteUserPopUp(false) }}
          onClick={() => setDeleteUserPopUp(false)}
          text="Are you sure you want to delete this Category?"
          buttondelete="Delete"
          buttonclose="Close"

        />
      </AddUser>

      {/* ====================================DELETE ORG ========================================== */}

      {/* ====================================UPDATE ORG ========================================== */}
      <AddUser
        openPopup={updatePopup}
        setOpenPopup={setUpdatePopup}
        title="Update Organization"
        onClick={handleUpdateClose}
        CatId={catId}
      >

        <UpdateCategory allOrgCategories={allOrgCategories} id={catId} onClose={handleUpdateClose} />


      </AddUser>


    </>
  );
}

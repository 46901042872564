import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@mui/material';


export default function AssignedPopup(props) {
    const { title, children, openPopup, onClick, onSubmit,textforward,onClose } = props;

    return (
        <div>
            <Dialog open={openPopup} maxWidth= "xl"  >
                <DialogTitle >
                    {title}
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button style={{backgroundColor:'#4db8ff', color:'white'}} onClick={onSubmit}>{textforward}</Button>
                    <Button style={{backgroundColor:'#ff6666', color:'white', width:'16%', textAlign:'center'}} onClick={onClick}>{onClose}</Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}
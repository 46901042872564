/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import React, { useEffect, input, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';

import dayjs from 'dayjs';

import { FormProvider, RHFTextField, RHSelect } from '../../components/hook-form/index';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function AddFirmForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [DateOfBirth, setDateOfBirth] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [message, setMessage] = React.useState('');
const [ allOrgCategories, setAllOrgCategories] = useState([])
const [ allOrg, setAllOrg] = useState([])
const [ catLabel, setCatLabel] = useState('')

  
  
  // --------------------------------------------------------------

  const handleDate = (newValue) => {
    setDateOfBirth(newValue);
  };


  // ---------------------------------------------------------
  const [image, setImage] = React.useState('');
  const handlePhoto = (event) => {
    setImage(event.target.value);
    console.log(image);
  };
  // const [showPassword, setShowPassword] = useState(false);
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const LoginSchema = Yup.object().shape({
    organization: Yup.string().required('Organization Title is required'),
    email: Yup.string().email('Email must be in a valid email formate').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Password must match'),
    city: Yup.string(),
    contact: Yup.string().required('Contact is required').matches(phoneRegExp, 'Phone number does not match the formate').min(11, "to short")
    .max(11, "to long"),
    address1: Yup.string(),
    category: Yup.string().required('Category is required')
  });

  const defaultValues = {
    organization: '',
    category: '',
    email: '',
    password: '',
    confPassword: '',
    city: '',
    contact: '',
    address1: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  
 
  const onSubmit = async (data) => {
    const dataObject = {
      'org_title': data.organization,
      'org_cat': data.category,
      'role': 'firm',
      'email': data.email,
      'password' : data.password,
      'conf_password' : data.confPassword,
      'city': data.city,
      'contact': data.contact,
      'address' : data.address1,
      'image': base64code
    }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: console.log(dataObject)
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_user', requestOptions)
        .then(response => response.json())
        .then(data => {
         if(data.type === 'success'){
          window.scrollTo(0, 0)
          setMessage(data.message)
          setTimeout( () => { navigate('/admindashboard/admin/hr/manageuser', { replace: true}) }, 1500);
         
         }else{
          setMessage(data.message)
         } 
         console.log(data)
        });
  };

  // ----------------------------------------------

  const CATEGORY_OPTION = ['Select One', 'Male', 'Female'];
  const PreRequiredApproval = ['Select One', 'Yes', 'No'];


  const Roles = ['Select One', 'Admin', 'Employee', 'Firm'];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }


  
  const getOrgCategories = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organization_categories')
      .then((response) => response.json())
      .then((json) => {
        setAllOrgCategories(json.data); 
        return json.data
      })
      .then((data) =>{ setCatLabel(data[0].category_title)});
  };

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };
  useEffect(() => {
    getOrgCategories()
    getAllOrg()
  }, []);


  // ----------------------------------------------

    //  ============================= image to base64 ======================================= //
    let base64code = ""
    let UserImage;
    const onChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
    UserImage =file.name
  };
  
  const onLoad = fileString => {
    console.log(fileString);
    base64code = fileString
  };
  
  const getBase64 = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  
    
  //  ==================================== image to base64 ========================================= //

  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };
  return (
    <>
      {/* ======================= front Heading =============================================== */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        { message ? (
            <Typography style={{ display: 'flex' , justifyContent: 'center', backgroundColor: '#b3ffb3', alignItems : "center",   position: 'absolute', width: '70%', borderRadius: 10}} variant="h4" gutterBottom>
            <Typography style={{ color: 'green'   }} variant="h4" gutterBottom>
            {message}
          </Typography>
          </Typography>
        ) : null }
        
        <Typography variant="h4" gutterBottom>
          Add Organization
        </Typography>
        <Button
          variant="outlined"
          component={RouterLink}
          style={{ marginRight: 5 }}
          to="/admindashboard/admin/hr/manageorganization"
        >
          Organizations List
        </Button>
      </Stack>
      {/* ======================= front Heading end =============================================== */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} style={{ backgroundColor: 'white', padding: 30, borderRadius: 10, boxShadow: "1px 1px 1px #999999" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Name</p> */}
              <RHFTextField name="organization" label="Organization title" />
            </Grid>

            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Father Name</p> */}
              <RHFTextField name="category" label="Organization catagory" />
            </Grid>


            

            



            <Grid item xs={12} md={4} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>City</p> */}
              <RHFTextField name="city" label="Write city name" />
            </Grid>

            

            

          

            <Grid item xs={12} md={6}>
              <RHFTextField name="contact" label="Contact (03XXXXXXXXX)" />
            </Grid>

            <Grid item xs={12} md={6} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Email/Username</p> */}

              <RHFTextField name="email" label="Email address / Username" />
            </Grid>


           

            <Grid item xs={12} md={6} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Password</p> */}

              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Password</p> */}

              <RHFTextField
                name="confPassword"
                label="Confirm Password"
                type={showConfPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfPassword(!showConfPassword)} edge="end">
                        <Iconify icon={showConfPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} mb={2}>
              <input
                style={styles}
                type="file"
                name="image"
                label="Email address"
                value={UserImage}
                onChange={onChange}
              />
            </Grid>

           

            <Grid item xs={12} md={6} mb={2}>
              {/* <p style={{ fontSize: 12, marginBottom: 5 }}>Address </p> */}

              <RHFTextField name="address1" label="Address Line 1" />
            </Grid>

            <Grid item xs={12} md={3} mb={2}>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </>
  );
}

/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import * as React from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Avatar,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { faker } from '@faker-js/faker';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import AddUser from './AddUser';
import DeleteCategoryPopup from './DeleteCategory';
import UpdateCategory from './UpdateCategory';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: '', alignRight: false },
  { id: 'sno', label: 'S.NO', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'cnic', label: 'CNIC', alignRight: false },
  { id: 'phone', label: 'CONTACT', alignRight: false },
  { id: 'salary', label: 'CURRENT SALARY', alignRight: false },
  { id: '', label: '', alignRight: false },
  { id: '', label: '', alignRight: false },
  // { id: 'status', label: 'STATUS', alignRight: false },
  // { id: 'action', label: 'ACTION', alignRight: false },
];

// --------------------------------------------------
function Status(id) {
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(id);
  };

  return (
    <>
      <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
    </>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.organization.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AdminEmployee(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('organization');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [csvFile, setCsvFile] = React.useState('');
  const [csvDetialsFile, setCsvDetailsFile] = useState('');
  const [headMessage, setHeadMessage] = React.useState('');
  const [detailMessage, setDetailMessage] = React.useState('');
  const [deleteUserPopUp, setDeleteUserPopUp] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [catId, setCatId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [headAlertMessage, setHeadAlertMessage] = useState(false);
  const [detailAlertMessage, setDetailAlertMessage] = useState(false);
  const [emptyFileError, setEmptyFileError] = useState(true);
  const [detailOrgErrMessage, setDetailOrgErrMessage] = useState('');
  const [headOrgErrMessage, setHeadOrgErrMessage] = useState('');
  const [allOrgCategories, setAllOrgCategories] = useState([]);
  const [tab, setTab] = useState('tab1');
  const [detailOrg, setDetailOrg] = React.useState(false);
  const [headOrg, setHeadOrg] = React.useState(false);
  const [allOrg, setAllOrg] = useState([]);
  const [headermessage,setHeaderMessage] = useState('')
  const [detailsmessage,setDetailsMessage] = useState('')


  const handleHeadOrgChange = (event) => {
    setHeadOrg(event.target.value);
    setHeadOrgErrMessage('')

    
  };
  const handleDetailOrgChange = (event) => {
    setDetailOrg(event.target.value);
    setDetailOrgErrMessage('')

  };

  //   {
  //     id: 'emp-001',
  //     sno: '1',
  //     name: 'Asif Turi',
  //     empid: 'emp-001',
  //     cnic: '38750-8563452-8',
  //     phone: '0345-9798745',
  //     address: 'G-9 Islamabad',
  //     loanlimit: '10,000',
  //     title: 'Software Developer',
  //     status: 'Pending',
  //   },
  //   {
  //     id: 'emp-002',
  //     sno: '2',
  //     name: 'Muhammad Saad',
  //     empid: 'emp-001',
  //     cnic: '38755-8564452-2',
  //     phone: '0344-9798645',
  //     address: 'Street # 24 Tarnool Rawalpindi',
  //     title: 'Content Writer',
  //     loanlimit: '14,550',
  //     status: 'Approval',
  //   },
  //   {
  //     id: 'emp-003',
  //     sno: '3',
  //     name: 'Zeeshan Haider',
  //     empid: 'emp-003',
  //     cnic: '38882-8565454-6',
  //     phone: '0311-97456645',
  //     address: 'ParaChanar',
  //     loanlimit: '20,000',
  //     title: 'Graphic Designer',
  //     status: 'Declined',
  //   },
  // ];
  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };

  // =========GET ORGANIZATIONS  API ============ //
  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };
  // =========HANDLE cLOSE ============ //
  const handleUpdateClose = () => {
    setUpdatePopup(false);
  };

  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleDeleteClose = () => {
    setDeleteUserPopUp(false);
  };

  const onSubmitHead = async () => {
    // create a new FormData object
    const formData = new FormData();
    formData.append('org_id', headOrg);
    formData.append('uploadFile', csvFile);
  
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/import_header_file', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (!headOrg) {
          setHeadOrgErrMessage('Please select organization');
        } else if(data.type){
          setHeaderMessage(data.message)
        }else {
          setHeadAlertMessage('');
        }
      })
   
  };
  

  const onSubmitDetail = async () => {
    const formData = new FormData();
    formData.append('org_id', detailOrg);
    formData.append('uploadFile', csvFile);
  
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/import_header_file', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (!detailOrg) {
          setDetailOrgErrMessage('Please select organization');
        } else if(data.type){
          setDetailsMessage(data.message)
        }else {
          setDetailOrgErrMessage('')

        }
      })
   
  };




  const location = useLocation();
  const receivedData = location.state && location.state.data;

  const getOrgCategories = () => {
    const requestOptionss = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ org_id: receivedData }),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_users_by_organization', requestOptionss)
      .then((response) => response.json())
      .then((json) => setAllOrgCategories(json.data));
  };

  useEffect(() => {
    getOrgCategories();
    getAllOrg();
  }, []);
  // const onClickEvent = () => {
  //   Data.push( { id: Data.length + 1, organization: enterValue})
  //   handleClose()
  //   console.log(Data)

  // }

  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allOrgCategories.map((n) => n.org_title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allOrgCategories.length) : 0;
  const filteredUsers = applySortFilter(allOrgCategories, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  // ======================  Filter end =====================================//
  // ======================  Org Delete start =====================================//
  function refreshPage() {
    window.location.reload(false);
  }
  const navigate = useNavigate();

  const deleteOrg = async () => {
    console.log(JSON.stringify({ category_id: deleteId }));

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ category_id: deleteId }),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_organization_category', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
            refreshPage();
            setDeleteUserPopUp(false);
          }, 1000);
        } else {
          setMessage(data.message);
        }
      });
  };

  //  ============================= image to base64 ======================================= //
  // let base64code = '';
  let UserImage;
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
  
      // create a new FormData object
      const formData = new FormData();
      // append the file to the formData as a binary
      formData.append('file', file);
  
      // send the formData to the server using fetch or axios
      // for example:
      // fetch('/upload', {
      //   method: 'POST',
      //   body: formData,
      // });
  
      setCsvFile(file);
      setEmptyFileError(false)
      if (e) {
        setHeadAlertMessage(false);
        setHeadMessage('');
      }
    } else {
      setHeadAlertMessage(true);
      setHeadMessage('Please Upload Excel File');
    }
  };

  
  const onChangeDetails = (e) => {
    const files = e.target.files;
    const file = files[0];
    if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const data = new Uint8Array(e.target.result);
      //   const workbook = XLSX.read(data, { type: 'array' });
      //   const firstSheetName = workbook.SheetNames[0];
      //   const worksheet = workbook.Sheets[firstSheetName];
      //   console.log(worksheet);
      //   // use jsonData here to update state or pass it to other components
      // };
      setCsvDetailsFile(file);
      setEmptyFileError(false)
      if (e) {
        setDetailAlertMessage(false);
        setDetailMessage('');
      }
    } else {
      setDetailAlertMessage(true);
      setDetailMessage('Please Upload Excel File');
    }
  };

  // const onLoad = (fileString) => {
  //   console.log(fileString);
  //   base64code = fileString;
  // };

  //  ==================================== image to base64 ========================================= //

  // ======================  Org Delete end =====================================//

  return (
    <>
      <Page title="User">
        <Container>
          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Upload Employee
            </Typography>
            <Stack direction={'row'}>
              <Button
                style={{
                  backgroundColor: 'white',
                  border: '1px solid blue',
                  marginRight: 5,
                }}
                component={RouterLink}
                to="/admindashboard/admin/hr/add"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Employee
              </Button>

              <Button
                onClick={() => setOpenPopup(true)}
                style={{
                  backgroundColor: 'white',
                  border: '1px solid blue',
                }}
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Upload Employee
              </Button>
            </Stack>
          </Stack>

          {/* ======================= front Heading end =============================================== */}

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table style={{ width: '100%' }}>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={allOrgCategories.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { id, sno, name, user_id, cnic, contact, address, loan_limit, status, title} = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox" />

                          <TableCell align="left" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {index + 1}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell width="20%" align="left">
                            {name}
                          </TableCell>
                          <TableCell width="20%" align="left">
                            {cnic}
                          </TableCell>

                          <TableCell width="10%" align="left">
                            {contact}
                          </TableCell>

                          <TableCell width="20%" align="left">
                            {address}
                          </TableCell>

                          <TableCell width="10%" align="left" />

                          <TableCell width="10%" align="left" />
                          <TableCell width="10%" align="left" />

                          {/* tutk
                          </TableCell> */}
                          {/* <TableCell align="center">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                          {/* <TableCell width="30%" align="center">
                            {loan_limit}
                          </TableCell> */}

                          {/* <TableCell width="15%" align="center">
                            <Typography style={{ borderRadius: 7, fontWeight: 'bold' }}/> 
                          </TableCell> */}

                          {/* <TableCell width="60%" align="left" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                              // component={RouterLink}
                              onClick={() => {
                                setUpdatePopup(true);
                                setUserId(id);
                                // console.log(user_id )
                              }}
                              to="#"
                              style={{ margin: 12 }}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setDeleteUserPopUp(true);
                                setUserId(user_id);
                              }}
                              component={RouterLink}
                              to="#"
                            >
                              Delete
                            </Button>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allOrgCategories.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      {/* ====================================UPLOAD EMPLOYEES FILE ========================================== */}

      <AddUser openPopup={openPopup} setOpenPopup={setOpenPopup} title="Upload Employee" onClick={handleClose}>
      <Typography style={{ color: 'red', fontSize: 18, alignItems:'center', justifyContent:'center', marginBottom:15}} variant="h4">{headermessage|| detailsmessage}</Typography>
        {headMessage || detailMessage ? (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // position: 'absolute',
              width: '100%',
              borderRadius: 10,
              marginBottom: 20,
              zIndex: 99,
            }}
            variant="h6"
          >
            <Typography style={{ color: 'red', fontSize: 12 }} variant="h6">
              {headMessage || detailMessage}
            </Typography>
          </Typography>
        ) : null}
        <Grid container spacing={3}>
          <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} item xs={12}>
            <Button
              onClick={() => {
                setTab('tab1');
              }}
              variant="contained"
              sx={{
                borderRadius: 0.5,
              }}
              color={tab === 'tab1' ? 'primary' : 'inherit'}
            >
              <Typography>Header File</Typography>
            </Button>
            {/* <Button
              onClick={() => {
                setTab('tab2');
              }}
              variant="contained"
              sx={{
                borderRadius: 0.5,
              }}
              color={tab === 'tab2' ? 'primary' : 'inherit'}
            >
              <Typography>Detail File</Typography>
            </Button> */}
          </Grid>

          <Grid item xs={12}>
            {tab === 'tab1' ? (
              <Stack spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={headOrg}
                      label="Organization"
                      onChange={handleHeadOrgChange}
                    >
                      {allOrg?.map((item, index) => (
                        <MenuItem key={index} value={item.org_id}>
                          {item.org_title}
                        </MenuItem>
                      ))}
                    </Select>
                    {headOrgErrMessage ? <Typography style={{ color: 'red', fontSize: 12 }} variant="p">{headOrgErrMessage}</Typography> : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: 12, marginBottom: 5 }}>Upload Csv </p>
                    <p style={{ fontSize: 10, marginBottom: 5, color: 'green' }}>Download Sample Csv </p>
                  </div>
                  {/* <input type="file" onChange={onChange} className="filetype" />               */}
                  <input
                    accept=".xls,.xlsx"
                    style={styles}
                    type="file"
                    name="excel"
                    label="Upload Excel"
                    onChange={onChange}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={headAlertMessage || emptyFileError}
                    onClick={onSubmitHead}
                  >
                    Upload
                  </Button>
                </Grid>
              </Stack>
            ) : (
              <Stack spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={detailOrg}
                      label="Organization"
                      onChange={handleDetailOrgChange}
                    >
                      {allOrg?.map((item, index) => (
                        <MenuItem key={index} value={item.org_id}>
                          {item.org_title}
                        </MenuItem>
                      ))}
                    </Select>
                    {detailOrgErrMessage ? <Typography style={{ color: 'red', fontSize: 12 }} variant="p">{detailOrgErrMessage}</Typography> : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: 12, marginBottom: 5 }}>Upload Csv </p>
                    <p style={{ fontSize: 10, marginBottom: 5, color: 'green' }}>Download Sample Csv </p>
                  </div>
                  {/* <input type="file" onChange={onChange} className="filetype" />               */}
                  <input
                    accept=".xls,.xlsx"
                    style={styles}
                    type="file"
                    name="excel"
                    label="Upload Excel"
                    onChange={onChangeDetails}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={detailAlertMessage || emptyFileError}
                    onClick={onSubmitDetail}
                  >
                    Upload
                  </Button>
                </Grid>
              </Stack>
            )}
          </Grid>
        </Grid>
      </AddUser>

      {/* ====================================DELETE ORG ========================================== */}
      <AddUser
        openPopup={deleteUserPopUp}
        setOpenPopup={setDeleteUserPopUp}
        title="Delete Category"
        onClick={handleDeleteClose}
        CatId={catId}
      >
        <DeleteCategoryPopup
          ClickDelete={() => {
            deleteOrg();
            setDeleteUserPopUp(false);
          }}
          onClick={() => setDeleteUserPopUp(false)}
          text="Are you sure you want to delete this Category?"
          buttondelete="Delete"
          buttonclose="Close"
        />
      </AddUser>

      {/* ====================================DELETE ORG ========================================== */}

      {/* ====================================UPDATE ORG ========================================== */}
      <AddUser
        openPopup={updatePopup}
        setOpenPopup={setUpdatePopup}
        title="Update Organization"
        onClick={handleUpdateClose}
        CatId={catId}
      >
        <UpdateCategory allOrgCategories={allOrgCategories} id={catId} onClose={handleUpdateClose} />
      </AddUser>
    </>
  );
}

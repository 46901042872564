import React, { useEffect, useState, useRef, } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Grid, Container, Typography, Card, Stack, Avatar, Box, Button, CardHeader } from '@mui/material';
import Page from '../../components/Page'
import {AssignedPopup} from '../../sections/@dashboard/user'

const List = [
  {
    id: 'emp-001',
    name: 'John',
    title: 'Admin',
    status: 'assigned',
    avatar:'https://cdn.pixabay.com/photo/2020/09/18/22/05/man-5583034_960_720.jpg'
  },
  {
    id: 'emp-002',
    name: 'Vikram',
    title: 'Employee',
    status: 'unassigned',
    avatar:'http://www.zbrushcentral.com/uploads/default/original/4X/6/3/f/63f7c0eb56a8fbfc737615797606f3a5c4bba747.jpeg'
  }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Admin",
  "Employee",
  "Firm"
];


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function useIsMountedRef() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}

export default function AssignRole() {
  const array = []
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const displayuser = orderBy(List, ['title'], ['desc']);
  const isMountedRef = useIsMountedRef();


   
  // ============================ POP UP =======================================================//
  const [status, setStatus] = useState(false)
  const [unAssignedStatus, setUnAssignedStatus] = useState(false)
  const [ anyId, setAnyId] = useState('');


// ========================= POP UP  ASSIGN CLOSE =============================//
  const handleStatusClose = () => {
    setStatus(false);
  };

  const showPopUp = (id) => {
    setStatus(true)
    setAnyId(id)
  }

  const handleStatus = () =>{
    const dis = displayuser.map((user) => {
      if(user.id === anyId) {
        user.status = 'unassigned';
      }
       return null;
    }
      )
    handleStatusClose()
    return dis;

  }
// ========================= POP UP  ASSIGN CLOSE =============================//

// ========================= POP UP UN-ASSIGN CLOSE =============================//

  const handleUnAssignedStatusClose = () => {
    setUnAssignedStatus(false);
  };

  const showAssignPop = (id) => {
    setUnAssignedStatus(true)
    setAnyId(id)
  }

  const handleUnAssignedStatus = () =>{
    const dis = displayuser.map((user) => {
      if( user.id === anyId){
      user.status = 'assigned'
      }
      return null;

   }
     )
     handleUnAssignedStatusClose()
   return dis;

  }
// ========================= POP UP UN-ASSIGN CLOSE =============================//




  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>

      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Assign Role
          </Typography>



          <Grid container justifyContent="center"  >

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Select Role</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Select Role" />}
                MenuProps={MenuProps}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                {names.map((name, index) => (
                  <MenuItem
                    key={index}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* ======================================== bottom card ================================================ */}
          <Grid container spacing={3}>

            <Grid item xs={12} md={6} mt={6}>

              <Card sx={{ minHeight: 500, background: '#4db8ff' }}>
                <CardHeader style={{
                  width: '100%',
                  // justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                  display: 'flex',
                  marginTop: 0,
                  flex: 1,
                  height: 70,
                  color: 'white',
                }} title="Assigned Users" />
                <Stack spacing={3} sx={{ p: 3 }}>

                  {displayuser.map((row, index) => {
                    const { id, name, title, header, status, avatar } = row;
                    if (status === 'assigned') {
                      return (

                        <Stack key={index} direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={name} src={avatar} />
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="subtitle2" color='white'>{name}</Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                mt: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                color: 'white'
                              }}>
                              {title}
                            </Typography>
                          </Box>
                          <Button style={{backgroundColor:'white', color:'#4db8ff'}} onClick={() => showPopUp(id)}   component={RouterLink} to="#">Un-Assign</Button>
                        </Stack>
                      )
                    }
                    return null;
                  })}
                </Stack>
              </Card>
            </Grid>
            {/*  ===============================================2nd card ============================================================== */}
            <Grid item xs={12} md={6} mt={6}>

              <Card sx={{ minHeight: 500, background: '#FFFFF' }}>
                <CardHeader style={{
                  width: '100%',
                  // justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'column',
                  display: 'flex',
                  marginTop: 0,
                  flex: 1,
                  height: 70,
                }} title="Un-Assigned Users" />
                <Stack spacing={3} sx={{ p: 3 }}>
                  {displayuser.map((row, index) => {
                    const { name, title, avatar, status, id } = row;
                    if (status === 'unassigned') {
                      return (
                        <Stack key={index} direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={name} src={avatar} />
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="subtitle2">{name}</Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                mt: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                color: 'text.secondary',
                              }}>
                              {title}
                            </Typography>
                          </Box>
                          <Button style={{backgroundColor:'#4db8ff', color:'white'}} onClick={()=> showAssignPop(id)} component={RouterLink} to="#"> Assign</Button>
                        </Stack>
                      )
                    }
                    return null;
                  })}
                </Stack>
              </Card>
            </Grid>
          </Grid>

        </Container>
      </Page>
      <AssignedPopup
        openPopup={status}
        setOpenPopup={setStatus}
        title="Re-Submit Application"
        children="Are you sure you want to Un-Assign?"
        onClick={handleStatusClose}
        onSubmit={handleStatus}
        textforward="Un-Assign"
        onClose="Close"
      />
        <AssignedPopup
        openPopup={unAssignedStatus}
        setOpenPopup={setUnAssignedStatus}
        title="Un-Assigned"
        children="Are you sure you want to Assign?"
        onClick={handleUnAssignedStatusClose}
        onSubmit={handleUnAssignedStatus}
        textforward="Assign"
        onClose="Close"
      />
    </>

  )
}
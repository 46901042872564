/* eslint-disable no-nested-ternary */
import React,{useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography,Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import account from '../_mock/account';
import {
  AppWelcome,
  TermCondition,
  Installment,
  PendingDue,
  UserProfile,
  AppWidget,
  AppWidgetSummary
} from '../sections/@dashboard/app';
import useSettings  from '../components/DashboardComponent/useSettings';
import {
  BankingBalanceStatistics
} from '../sections/@dashboard/banking';
import {
  EcommerceYearlySales,
  EcommerceWidgetSummary
} from '../sections/@dashboard/e-commerce';



// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const[ applicantData, setApplicantData ] = useState('');
  const[getAllApplication, setGetAllApplication] = useState([])
  useEffect(()=> {
    const items = JSON.parse(localStorage.getItem('data'));
    if (items) {
      setApplicantData(items.user)
    }
  },[])

  const AllApplication = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_applications')
      .then((response) => response.json())
      .then((json) => setGetAllApplication(json.data));
  };
  // console.log(applicantData)
  useEffect(() => {
    AllApplication();
  }, []);

  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(route);
  };
  
const pendingCount = getAllApplication.filter((item)=> item.status === '0').length;
const approvedCount = getAllApplication.filter((item)=> item.status === '2').length;

  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          
            <AppWidgetSummary
              title="Pending"
              percent={2.6}
              total={pendingCount}
              chartColor={theme.palette.primary.main}
            />
          </Grid> 

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Approved"
              percent={2.6}
              total={approvedCount}
              chartColor={theme.palette.primary.main}
            />
          </Grid> 
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Declined"
              percent={2.6}
              total={12}
              chartColor={theme.palette.primary.main}
            />
          </Grid> 
        <Grid item xs={12} md={6} lg={8}>
            <EcommerceYearlySales />
          </Grid>

          <Grid item xs={12} md={6} lg={4} >
            <Stack spacing={3}>
              <AppWidget title="Total Applications" total={36} icon={'eva:person-fill'} chartData={100} />
              <AppWidget title="Total Disbursed Amount" total={38566} icon={'eva:person-fill'} chartData={48} />
              <AppWidget title="Total Due Amount" total={22266} icon={'eva:email-fill'} color="warning" chartData={75} />
            </Stack>
          </Grid>


          <Grid item xs={12} md={6} lg={8}>
            <Stack spacing={3}>
              <BankingBalanceStatistics />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={4} >
            <Stack spacing={3}>
              <AppWidget title="Total Users" total={385} icon={'eva:person-fill'} chartData={48} />
              <AppWidget title="New Users" total={237} icon={'eva:email-fill'} color="warning" chartData={75} />
              <AppWidget title="Repeated Users" total={135} icon={'eva:email-fill'} color="warning" chartData={75} />

            </Stack>
          </Grid>
        
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
           Welcome back
        </Typography>

        <Grid container spacing={3}>


        <Grid item xs={12} md={8}>
            <AppWelcome displayName='Muzammil' />
          </Grid> */}
          {/* <Grid item xs={12} md={5}>
            <UserProfile title="Profile" src={applicantData.image} alt="photoURL"
              name={applicantData.name}
              profession={applicantData.username}
              dateofbirth={applicantData.date_of_birth ? applicantData.date_of_birth.substr(0,10) : null}
              location={applicantData.address} 
              email={applicantData.email}
              contact={applicantData.contact} />
          </Grid>



          <Grid item xs={12} md={7}>
            <Installment title={applicantData.user_type === '1' ? 'Applications' : applicantData.user_type === '2'? 'Applications' : applicantData.user_type === '3'? 'Installments' : null } />

            <Grid container spacing={3} mt={2}>

              <Grid item xs={12} md={4}>
                <PendingDue
                  style={{ backgroundColor: ' #ffcccc' }}
                  name={applicantData.user_type === '1' ? 'Total' : applicantData.user_type === '2'? 'Total' : applicantData.user_type === '3'? 'Total' : null } 
                  value={getAllApplication.length}
                  icon={<Iconify icon={'cil:badge'} color="#13002b" width={32} height={32} />}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <PendingDue
                  name={applicantData.user_type === '1' ? 'Pending' : applicantData.user_type === '2'? 'Pending' : applicantData.user_type === '3'? 'Pending' : null }
                  value={pendingCount}
                  icon={<Iconify icon={'cil:badge'} color="#13002b" width={32} height={32} />}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <PendingDue
                  name={applicantData.user_type === '1' ? 'Approved' : applicantData.user_type === '2'? 'Approved' : applicantData.user_type === '3'? 'Due' : null }
                  value={approvedCount}
                  icon={<Iconify icon={'cil:badge'} color="#13002b" width={32} height={32} />}
                />
              </Grid>

            </Grid>



            <Grid item xs={12} md={12}>
              <TermCondition style={{ marginTop: 15 }}
                title=" Terms and Conditions"
                list={[...Array(3)].map((_, index) => ({
                  title: [
                    'Criteria',
                    'Amount',
                    'Tenure',
                  ][index],
                  med: [
                    'Permanent Employees of any organization and Evidence of monthly salary (via payroll administration) over the last three (3) months.',
                    'As may be approved by HR of specific school.',
                    '180 days, with repayment over six (6) monthly installments.',
                  ][index],
                  type: `order${index + 2}`,
                }))}
              />
            </Grid> */}

          {/* </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}

/* eslint-disable camelcase */
import React, { useState, input } from 'react';
import { Stack, Grid, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function DeleteUserPopUp({ onClick, text, buttondelete, buttonclose, id }) {
  const [message, setMessage] = React.useState('');
  const [openPopup, setOpenPopup] = useState(false);
  
  const handleClose = () => {
    setOpenPopup(false);
  };

  function refreshPage() {
    window.location.reload(false);
  }
  const deleteUser = () => {
    const data ={
      "user_id" : id
    }
    console.log(data)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/delete_user', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.type === 'success') {
          window.scrollTo(0, 0);
          setMessage(data.message);

          setTimeout(() => {
            handleClose();
            refreshPage();
            setMessage('');
          }, 1000);
        } else {
          setMessage(data.message);
        }
      });
  };
  return (
    <Stack spacing={2}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} md={12}>
          <p style={{ fontSize: 15, marginBottom: 5, color: 'gray' }}>{text}</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button style={{ height: 40 }} onClick={()=> deleteUser()} fullWidth size="large" type="submit" variant="outlined">
            {buttondelete}
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            style={{ height: 40 }}
            onClick={onClick}
            color="error"
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
          >
            {buttonclose}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, MenuItem } from '@material-ui/core';
import { Stack, Grid, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  cnic: Yup.string().required('CNIC is required').matches('^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$', 'CNIC is not valid'),
  contact: Yup.string()
    .required('Contact is required')
    .matches(phoneRegExp, 'Phone number does not match the formate')
    .min(11, 'to short')
    .max(11, 'to long'),
  organization: Yup.string().required('Last name is required'),
  salary: Yup.array().of(
    Yup.object().shape({
      salaryDate: Yup.string().required('Salary Date is required'),
      month: Yup.string().required('Month is required'),
      netSalary: Yup.string().required('Month is required'),
    })
  ),
});

const defaultValues = {
  name: '',
  cnic: '',
  contact: '',
  organization: '',
  salary: [{ salaryDate: '', month: '', netSalary: '' }],
};

const MonthArray = [
  'Select One',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Form = () => {
  const [message, setMessage] = React.useState('');
  const [allOrg, setAllOrg] = React.useState([]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1)
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = methods;
  const salary = watch('salary');

  const handleAddField = () => {
    reset({ salary: [...salary, { salaryDate: '', month: '', netSalary: '' }] });
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...salary];
    updatedFields.splice(index, 1);
    reset({ salary: updatedFields });
  };

  //   /* ======================= API Function =============================================== */

  const getAllOrg = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_organizations')
      .then((response) => response.json())
      .then((json) => setAllOrg(json.data));
  };

  //   /* ======================= API Caling =============================================== */

  React.useEffect(() => {
    getAllOrg();
  }, []);

  //   /* ======================= API Caling =============================================== */

  return (
    <>
      {/* ======================= front Heading =============================================== */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {message ? (
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#b3ffb3',
              alignItems: 'center',
              position: 'absolute',
              width: '70%',
              borderRadius: 10,
            }}
            variant="h4"
            gutterBottom
          >
            <Typography style={{ color: 'green' }} variant="h4" gutterBottom>
              {message}
            </Typography>
          </Typography>
        ) : null}

        <Typography variant="h4" gutterBottom>
          Add User
        </Typography>
        <Button
          variant="outlined"
          component={RouterLink}
          style={{ marginRight: 5 }}
          onClick={handleBackClick}
          to="#"
        >
          User List
        </Button>
      </Stack>
      {/* ======================= front Heading end =============================================== */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => console.log(data))}>
          <Stack
            spacing={3}
            style={{ backgroundColor: 'white', padding: 30, borderRadius: 10, boxShadow: '1px 1px 1px #999999' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} mb={2}>
                <TextField
                  {...methods.register('name')}
                  label="Name"
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <TextField
                  {...methods.register('cnic')}
                  label="CNIC (xxxxx-xxxxxxx-x)"
                  error={Boolean(errors.cnic)}
                  helperText={errors.cnic?.message}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <TextField
                  {...methods.register('contact')}
                  label="Contact (03xxxxxxxxx)"
                  error={Boolean(errors.contact)}
                  helperText={errors.contact?.message}
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <TextField
                  style={{ width: 220 }}
                  {...methods.register(`organization`)}
                  label="Organization"
                  error={Boolean(errors.organization)}
                  helperText={errors.organization?.message}
                  variant="outlined"
                  select
                >
                  {allOrg?.map((item, index) => (
                    <MenuItem key={index} value={item.org_id}>
                      {item.org_title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {salary.map((field, index) => (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} key={index}>
                    <Grid item xs={12} md={3} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date of Salary Credit"
                          value={field.salaryDate} // value of salaryDate from the current field object
                          onChange={(date) => {
                            const updatedFields = [...salary];
                            updatedFields[index].salaryDate = date; // update salaryDate value of the current field object
                            reset({ salary: updatedFields }); // reset the salary array with updatedFields
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.salary?.[index]?.salaryDate)}
                              helperText={errors.salary?.[index]?.salaryDate?.message}
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={3} mb={2}>
                      <TextField
                        style={{ width: 220 }}
                        {...methods.register(`salary.${index}.month`)}
                        label="month"
                        error={Boolean(errors.salary?.[index]?.month)}
                        helperText={errors.salary?.[index]?.month?.message}
                        variant="outlined"
                        select
                      >
                        {MonthArray?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} mb={2}>
                      <TextField
                        {...methods.register(`salary.${index}.netSalary`)}
                        label="Net Salary"
                        error={Boolean(errors.salary?.[index]?.netSalary)}
                        helperText={errors.salary?.[index]?.netSalary?.message}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'center', aligItems: 'center', height: 55 }}>
                      {index === 0 ? (
                        <Button onClick={() => handleAddField()}>
                          <Icon
                            icon="ic:baseline-plus"
                            style={{ fontSize: '24px', color: '#fff', backgroundColor: '#0003bf', borderRadius: 50 }}
                          />
                        </Button>
                      ) : null}
                      {index > 0 ? (
                        <Button onClick={() => handleRemoveField(index)}>
                          <Icon
                            icon="ic:baseline-minus"
                            style={{ fontSize: '24px', color: '#fff', backgroundColor: '#52000b', borderRadius: 50 }}
                          />
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ))}
              </Grid>

              <Grid item xs={12} md={3} mb={2}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;

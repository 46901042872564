/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import * as React from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, Link } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Status from '../Admin/Status';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
  UserDetail,
} from '../../sections/@dashboard/user';

// mock
// import AddUser from './AddUser';
// import AddUserForm from './AddUserForm';
// import ImportUser from './ImportUser';
// import DeleteUserPopUp from './DeleteUserPopUp';
import ShowRepaymentPlan from '../Admin/ShowRepaymentPlan';
// ----------------------------------------------------------------------

  const TABLE_HEAD = [
  { id: 'id', label: ' ', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'date', label: 'DATE', alignRight: false },
  { id: 'amountrequest', label: 'Amount Request', alignRight: false },
  { id: 'organization', label: 'ORGANIZATION', alignRight: false },
  { id: 'installment', label: 'INSTALLMENT', alignRight: false },
  { id: 'status', label: 'STATUS', alignRight: false },
];

// ----------------------------------------------------------------------




// --------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ApprovalFirmApplication() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [importPopUp, setImportPopUp] = useState(false);
  const [ApproveApplicationPopUp, setApproveApplicationPopUp] = useState([]);
  const [getAllApplication, setGetAllApplication] = useState([]);
  let data = []
 
  // ====================== ASCENDING Desending =====================================//

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // ====================== POP UP ================================================= //
  const handleClose = () => {
    setOpenPopup(false);
  };


  // ======================  CheckBox to select =====================================//

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getAllApplication.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ======================  Filter =====================================//

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getAllApplication.length) : 0;

  const filteredUsers = applySortFilter(getAllApplication, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const loggedData = JSON.parse(localStorage.getItem('data'));


  // ======================  Filter end =====================================//
  const AllApplication = () => {
    fetch('https://lms.firefly-techsolutions.com/services/web/api/get_all_applications')
      .then((response) => response.json())
      .then((json) => {
        data = json.data.filter( el => el.status === '2')
        setGetAllApplication(data.filter(fil=>fil.org_id === loggedData.user.org_id))

      })
  };
  useEffect(() => {
    AllApplication();
  }, [getAllApplication]);
  return (
    <>
      <Page title="User">
        <Container>
          {/* ======================= front Heading =============================================== */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Approved Aapplication
            </Typography>
   
          </Stack>
          {/* ======================= front Heading end =============================================== */}

          <Card style={{width:'100%'}}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table style={{ width: '100%' }}>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={getAllApplication.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { id, name, sdt, org_title, expected_amount, avatarUrl, status, installment, request } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          style={{width: '100%',}}
                        >

                          <TableCell  align="left" component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell width="20%" align="left">
                            {name}
                          </TableCell>

                          <TableCell width="20%" align="left">
                            {sdt}
                          </TableCell>

                          <TableCell width="10%" align="left">
                            {expected_amount}
                          </TableCell>
                          <TableCell width="10%" align="left">
                            {org_title}
                          </TableCell>

                          {/* <TableCell align="center">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                          <TableCell width="10%" align="center">
                            {installment}
                          </TableCell>
                          <TableCell width='20%' align="center"><Typography style={{ backgroundColor: '#5bd75b', borderRadius: 7, color: 'white', fontWeight: 'bold'}}> Approved</Typography></TableCell>
                        
                       
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getAllApplication.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>

      <UserDetail
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Repayment Plan"
        onClick={handleClose}
        
      >
        <ShowRepaymentPlan />
      </UserDetail>
    </>
  );
}

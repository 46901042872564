import * as Yup from 'yup';
import React, { useState, input } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, TextField, Typography, Button} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { FormProvider, RHFTextField, RHSelect } from '../components/hook-form/index';
import Iconify from '../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const [userId, setUserId] = useState('')
  const [joiningDate, setJoiningDate] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [uploadCnic, setUploadCnic] = React.useState('');
  const [uploadContract, setUploadContract] = React.useState('');


  const [message, setMessage] = React.useState('');


  const navigate = useNavigate();
  
  const items = true
  let userID 
  if (items) {
  const items = JSON.parse(localStorage.getItem('data'));
  // console.log(items)
  userID = items.user.user_id

}

  // --------------------------------------------------------------

  const handleJoining = (newValue) => {
    setJoiningDate(newValue);
    console.log(joiningDate);
    
  };
  // ---------------------------------------------------------
  const handlePhoto = (event) => {
    setUploadCnic(event.target.value);
  };

  const handleUploadContract = (event) => {
    setUploadContract(event.target.value);
    console.log(uploadContract);
  };

  // const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    current_salary: Yup.string().required('name is required'),
    expected_amount: Yup.string().required('cnic is required'),


  });

  const defaultValues = {
    current_salary: '',
    expected_amount: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (data) => {
    const formData = {
      "joining_date": joiningDate,
      'current_salary': data.current_salary,
      "expected_amount": data.expected_amount,
      "cnic_upload": uploadCnic,
      "contract_upload": uploadContract,
      "user_id" : userID
    }
    console.log(JSON.stringify(formData))
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };
    fetch('https://lms.firefly-techsolutions.com/services/web/api/add_application', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.type === 'success') {
          window.scrollTo(0, 0)
          setMessage(data.message)
          setTimeout(() => { navigate('/dashboard/user/form', { replace: true }) }, 1500);

        } else {
          setMessage(data.message)
        }
        console.log(data)
      });
  };


  // ----------------------------------------------

  const styles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: 55,
    padding: 5,
    borderRadius: 7,
    width: '100%',
    paddingTop: 15,
  };
  return (
    <>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={8}>
        { message ? (
            <Typography style={{ display: 'flex' , justifyContent: 'center', backgroundColor: '#ccffcc', alignItems : "center",   position: 'absolute', width: '70%', borderRadius: 10}} variant="h6" gutterBottom>
            <Typography style={{ color: ' #00b33c', padding: 10, }} variant="h6" gutterBottom>
            {message}
          </Typography>
          </Typography>
        ) : null }
     
      </Stack>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{Bottom:40}}>

   
      <Stack spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>Date of Joining</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="MM/DD/YYYY"
                value={joiningDate}
                onChange={handleJoining}
                renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Current Salary</p>
            <RHFTextField name="current_salary" label="Write your father name" />
          </Grid>

          <Grid item xs={12} md={4}>
            <p style={{ fontSize: 12, marginBottom: 5, color: 'gray' }}>Expected Amount</p>
            <RHFTextField name="expected_amount" label="Expected Amount" />
          </Grid>

         

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>CNIC Upload</p>

            <input style={styles} type="file" name="upload_cnic" label="Upload Cnic" value={uploadCnic} onChange={handlePhoto} />
          </Grid>

          <Grid item xs={12} md={6}>
            <p style={{ fontSize: 12, marginBottom: 5 }}>Upload Contract</p>

            <input style={styles} type="file" name="upload_contract" label="Upload Contract" value={uploadContract} onChange={handleUploadContract} />
          </Grid>


          <Grid item xs={12} md={4}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
    </>
  );
}
 